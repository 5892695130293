<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Overview</h6>

            <!-- Title -->
            <h1 class="header-title">Dashboard</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
    <!-- Content Goes here -->
    <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Total Vehicles</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 2</span>

              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-truck text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Hours -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Total Jobs</h6>

                <!-- Heading -->
                <span class="h2 mb-0">
                  {{ totalJobs }}
                </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Exit -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Maintenance Due</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 5 </span>
              </div>
                <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-settings text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Renewals</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 2 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-file-text text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- / .row -->
    <!-- end of top cards -->
    <!-- jobs list -->
    <div class="row">
      <div class="col-12">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Recent Jobs</h4>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <router-link class="btn btn-sm btn-white"
                  :to="{ name: 'TransporterNewJob'}">
                    Add
                  </router-link>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <router-link :to="{name: 'TransporterJobs'}" class="btn btn-sm btn-white">
                  View All
                </router-link>
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div
            class="table-responsive mb-0"
          data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-project"
                    >
                      Customer
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Status
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Pick Up
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Drop Off
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Distance
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Revenue
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-date"
                    >
                      Pick up date
                    </a>
                  </th>
                  <th>Vehicle</th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="job in jobList" :key="job.id">
                  <td class="goal-project">{{ job.customer }}</td>
                  <td class="goal-status">
                    <div v-if="job.status === 0">
                      <span class="text-primary">●</span> Booked
                    </div>
                    <div v-if="job.status === 1">
                      <span class="text-warning">●</span> In Progress
                    </div>
                    <div v-if="job.status === 9">
                      <span class="text-success">●</span> Completed
                    </div>
                  </td>
                  <td class="goal-progress">
                    {{ job.pickup }}
                  </td>
                  <td class="goal-date">
                    {{ job.dropoff }}
                  </td>
                  <td class="text-start">
                    {{ job.distance }}
                  </td>
                  <td class="text-start">
                    GH&cent; {{ job.finalRevenue }}
                  </td>
                  <td class="text-start">
                    <time datetime="2018-10-24">{{ job.pickupDate.toDate().toDateString() }}</time>
                  </td>
                  <td class="text-start">
                    {{ job.truckRegistration }}
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterJobDetail', params: { id: job.id } }">
                          View
                        </router-link>
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterJobEdit', params: { id: job.id } }">
                          Edit
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from '@vue/runtime-dom';
import getUser from '../../../composables/getUser';
import { pentermFirestore } from '../../../firebase/config';

export default {
  name: 'DashBoard',
  components: {},
  setup() {
    const {
      user, getUserRole, transporterTIN, userDetails,
    } = getUser();
    const loggedInUser = ref('');
    const error = ref('');
    const dump = ref('');
    const totalJobs = ref('');
    const jobList = ref([]);
    const totalVehicles = ref('');
    const vehicleList = ref([]);
    const bookedJobs = ref('');
    const inProgressJobs = ref('');
    const completedJobs = ref('');
    // const router = useRouter();

    // if (window.location.hostname === 'localhost') {
    //   firebase.functions().useEmulator('localhost', 5001);
    // }

    // const getIsAdmin = firebase.functions().httpsCallable('checkUserAdminState');
    // getIsAdmin({ email: user.email }).then((result) => {
    //   // userAccount.value = result.data;
    //   if (result.data.isAdmin) {
    //     console.log('User is Admin');
    //   } else {
    //     console.log('User is a customer');
    //     router.push('user-dashboard');
    //   }
    //   console.log(result);
    // }).catch((err) => {
    //   // Getting the Error details.
    //   console.log('Error Code ', err.code);
    //   console.log('Error Message ', err.message);
    //   console.log('Error Message ', err.details);
    //   // ...
    // });

    ///
    const allJobs = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterJobs')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .limit(5)
          .onSnapshot((snap) => {
            jobList.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            totalJobs.value = snap.docs.length;
          });
        dump.value = res;
      } catch (err) {
        console.log(err.message);
        console.log('Error occured');
        error.value = err.message;
      }
    };
    // const allBookedJobs = async () => {
    //   try {
    //     const res = await pentermFirestore.collection('customerJobs').where('status', '==', 0)
    //       .onSnapshot((snap) => {
    //         bookedJobs.value = snap.docs.length;
    //       });
    //     dump.value = res;
    //   } catch (err) {
    //     console.log(err.message);
    //     console.log('Error occured');
    //     error.value = err.message;
    //   }
    // };
    // const allInProgressJobs = async () => {
    //   try {
    //     const res = await pentermFirestore.collection('customerJobs').where('status', '==', 1)
    //       .onSnapshot((snap) => {
    //         inProgressJobs.value = snap.docs.length;
    //       });
    //     dump.value = res;
    //   } catch (err) {
    //     console.log(err.message);
    //     console.log('Error occured');
    //     error.value = err.message;
    //   }
    // };
    // const allCompletedJobs = async () => {
    //   try {
    //     const res = await pentermFirestore.collection('customerJobs').where('status', '==', 2)
    //       .onSnapshot((snap) => {
    //         completedJobs.value = snap.docs.length;
    //       });
    //     dump.value = res;
    //   } catch (err) {
    //     console.log(err.message);
    //     console.log('Error occured');
    //     error.value = err.message;
    //   }
    // };
    const vehicles = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .limit(5)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            vehicleList.value = snap.docs.map((vehicle) => ({ ...vehicle.data(), id: vehicle.id }));
            // last = snap.docs[snap.docs.length - 1];
            totalVehicles.value = snap.docs.length;
            // console.log(last.data());
            // disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    onMounted(() => {
      allJobs();
      vehicles();
    });

    // allBookedJobs();
    // allInProgressJobs();
    // allCompletedJobs();
    return {
      user,
      loggedInUser,
      totalJobs,
      jobList,
      vehicleList,
      totalVehicles,
      bookedJobs,
      inProgressJobs,
      completedJobs,
      getUserRole,
      transporterTIN,
    };
  },
};
</script>
<style></style>
