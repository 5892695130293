<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Overview</h6>

            <!-- Title -->
            <h1 class="header-title">{{ getCompanyName }} Vehicle Registration</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->
<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <!-- Progress Bar -->
      <div class="card" v-if="progressBar">
        <div class="card-body">

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="`width:${ progressBar }%`"
            aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

        </div>
      </div>
  <!-- .end of progress -->
  <h4 class="text-start">Form<hr></h4>
  <div class="alert alert-danger" role="alert" v-if="driverData.length > 0">
    Driver email error duplicate entry.
  </div>

  <!-- <button class="btn btn-primary" @click="uploadImages">Click me</button> -->
      <form @submit.prevent="submitData" autocomplete="off" class="py-2">
        <div class="row error" v-if="dobError || licenseError || insuranceError">
          <div class="col-xs-12 col-md-12">
            <ul>
              <li v-if="dobError">{{ dobError }}</li>
              <li v-if="licenseError">{{ licenseError }}</li>
              <li v-if="insuranceError">{{ insuranceError }}</li>
            </ul>
          </div>
        </div>
          <!-- Row -->
          <!--  -->
      <div class="row">
        <!-- Vehicle Types -->
        <h4 class=" text-start text-danger">Vehicle Category:</h4>
        <div class="row">
          <div class="col-xs-12 col-md-2" v-for="pricing in dataResults" :key="pricing.id">
            {{ pricing.categoryName }}
            <input type="radio" :value="pricing.categoryName"
            v-model="categoryPicked" required name="vehicleType"/>
            <div class="vehicle-type">
              <img :src="`../../../img/${pricing.imagePath}`" height="40">
              <!-- <img :src=pricing.imagePath height="40"> -->
            </div>
          </div>
        </div>
        <div class="row py-2">
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" required v-model="vehicleMake">
                  <option value="">Select</option>
                  <option value="Scania">Scania</option>
                  <option value="Hino">Hino</option>
                  <option value="Iveco">Iveco</option>
                  <option value="MAN">MAN</option>
                  <option value="Volvo">Volvo</option>
                  <option value="Dongfeng">Dongfeng</option>
                  <option value="TATA">TATA</option>
                  <option value="Volkswagen">Volkswagen</option>
                  <option value="Daimler">Daimler</option>
                </select>
                <label>Manufacturer <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="vehicleReg">
                <label>Registration No. <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="vehicleVIN">
                <label>Chassis Number <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
              <select class="form-control"
                required v-model="vehicleMakeYear">
                <option v-for="year in makeYear" :key="year" :value="year">{{ year }}</option>
              </select>
                <label>Year <span class="required-field"> *</span></label>
              </div>
            </div>
            </div>
        <div class="row">
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" required v-model="insuranceCompany">
                  <option value="">Select</option>
                <option value="Activa International">Activa International </option>
                <option value="Allianz Insurance">Allianz Insurance</option>
                <option value="Bedrock Insurance">Bedrock Insurance</option>
                <option value="Best Assurance">Best Assurance</option>
                <option value="Donewell Insurance">Donewell Insurance</option>
                <option value="Enterprise Insurance">Enterprise Insurance</option>
                <option value="Ghana Union Assurance">Ghana Union Assurance </option>
                <option value="Glico General Insurance">Glico General Insurance</option>
                <option value="Hollard Insurance">Hollard Insurance</option>
                <option value="Imperial General Assurance">Imperial General Assurance</option>
                <option value="Loyalty Insurance">Loyalty Insurance </option>
                <option value="Millennium Insurance">Millennium Insurance</option>
                <option value="NSIA Insurance">NSIA Insurance</option>
                <option value="Phoenix Insurance">Phoenix Insurance</option>
                <option value="Prime Insurance ">Prime Insurance</option>
                <option value="Priority Insurance">Priority Insurance</option>
                <option value="Provident Insurance">Provident Insurance</option>
                <option value="Quality Insurance">Quality Insurance</option>
                <option value="RegencyNem Insurance">RegencyNem Insurance</option>
                <option value="Saham Insurance">Saham Insurance</option>
                <option value="Serene Insurance">Serene Insurance</option>
                <option value="SIC Insurance">SIC Insurance</option>
                <option value="Star Assurance">Star Assurance</option>
                <option value="SUNU Assurances">SUNU Assurances</option>
                <option value="Unique Insurance">Unique Insurance</option>
                <option value="Vanguard Assurance">Vanguard Assurance</option>
                <option value="Wapic Insurance">Wapic Insurance</option>
                </select>
                <label>Insurance Company<span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" required v-model="insuranceType">
                  <option value="">Select</option>
                  <option value="Comprehensive">Comprehensive</option>
                  <option value="3rd-Party">3rd-Party</option>
                </select>
                <label>Insurance Type <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                :class="[insuranceErrorStyle]"
                required v-model="insuranceStart"
                ref="insuranceStartDatePicker"
                >
                <label>Insurance Start</label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="date" class="form-control"
                :class="[motErrorStyle]"
                required v-model="motStart" ref="motStartDatePicker"
                >
                <label>Road Worthiness Start</label>
              </div>
            </div>
            </div>
        <div class="row">
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" v-model="GITinsuranceCompany">
                  <option value="">Select</option>
                <option value="Activa International">Activa International </option>
                <option value="Allianz Insurance">Allianz Insurance</option>
                <option value="Bedrock Insurance">Bedrock Insurance</option>
                <option value="Best Assurance">Best Assurance</option>
                <option value="Donewell Insurance">Donewell Insurance</option>
                <option value="Enterprise Insurance">Enterprise Insurance</option>
                <option value="Ghana Union Assurance">Ghana Union Assurance </option>
                <option value="Glico General Insurance">Glico General Insurance</option>
                <option value="Hollard Insurance">Hollard Insurance</option>
                <option value="Imperial General Assurance">Imperial General Assurance</option>
                <option value="Loyalty Insurance">Loyalty Insurance </option>
                <option value="Millennium Insurance">Millennium Insurance</option>
                <option value="NSIA Insurance">NSIA Insurance</option>
                <option value="Phoenix Insurance">Phoenix Insurance</option>
                <option value="Prime Insurance ">Prime Insurance</option>
                <option value="Priority Insurance">Priority Insurance</option>
                <option value="Provident Insurance">Provident Insurance</option>
                <option value="Quality Insurance">Quality Insurance</option>
                <option value="RegencyNem Insurance">RegencyNem Insurance</option>
                <option value="Saham Insurance">Saham Insurance</option>
                <option value="Serene Insurance">Serene Insurance</option>
                <option value="SIC Insurance">SIC Insurance</option>
                <option value="Star Assurance">Star Assurance</option>
                <option value="SUNU Assurances">SUNU Assurances</option>
                <option value="Unique Insurance">Unique Insurance</option>
                <option value="Vanguard Assurance">Vanguard Assurance</option>
                <option value="Wapic Insurance">Wapic Insurance</option>
                </select>
                <label>Goods In Transit Insurance
                  <!-- <span class="required-field"> *</span> -->
                  </label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" ref="GITStartDatePicker"
                :class="[insuranceErrorStyle]"
                v-model="GITinsuranceStart"
                >
                <label>Goods in Transit Insurance Start</label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                :class="[motErrorStyle]"
                required v-model="VITStart" ref="VITStartDatePicker"
                >
                <label>Vehicle Income Tax Start</label>
              </div>
            </div>
            </div>
        <!-- End of vehicle types -->
      </div>
      <h4 class=" text-start text-danger">
        Required Documents [ All Must images accepted formats *.png,*.jpg,*jpeg ]
        </h4>
            <div class="row py-2">
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Goods in Transit Insurance
                </h4>
                <label class="py-2">
                  <input type="file" id="goodsInTransitPhoto"
                  name="Goods in Transit Insurance" class="form-control"
                  capture="user" accept="image/*" @change="fileUpload" ref="docName">
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Vehicle Income Tax
                </h4>
                <label class="py-2">
                  <input type="file" id="vehiclIncomeTaxPhoto"
                  name="Vehicle Income Tax" class="form-control"
                  capture="user" accept="image/*" @change="fileUpload" ref="docName">
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Insurance Certificate
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="insurancePhoto" name="Insurance Certificate"
                  capture="user" accept="image/*"
                  @change="fileUpload">
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Road Worthy Certificate
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="roadWorthyPhoto" name="Road Worthy Certificate"
                  capture="user" accept="image/*" @change="fileUpload">
              </label>
            </div>
            </div>
        <!-- Submit -->
                <div class="row">
            <div class="col-xs-12 col-md-12"><span class="error">{{ error }}</span></div>
            <div class="col-xs-12 col-md-12 text-center" v-if="driverData.length === 0">
              <button class="btn btn-danger">Submit</button>
            </div>
          </div>
      <!-- form end -->
        </form>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref, onMounted,
} from 'vue';
import flatpickr from 'flatpickr';
import { useRouter } from 'vue-router';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
// import {
//   getStorage, useStorageEmulator, ref as imgRef, uploadBytes,
// } from 'firebase/storage';
import getUser from '../../composables/getUser';
import { timestamp, pentermFirestore } from '../../firebase/config';

export default ({
  components: {
  },
  setup() {
    const {
      user, transporterId, getUserRole, transporterUsers, transporterUid, getCompanyName,
    } = getUser();
    const router = useRouter();
    const fberror = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const error = ref('');
    const publicPath = process.env.BASE_URL;
    const categoryPicked = ref('');
    const vehicleMake = ref('');
    const vehicleReg = ref('');
    const vehicleVIN = ref('');
    const vehicleMakeYear = ref('');
    const makeYear = ref([]);
    const insuranceCompany = ref('');
    const insuranceType = ref('');
    const insuranceStart = ref('');
    const insuranceEnd = ref('');
    const motStart = ref('');
    const motEnd = ref('');
    const GITinsuranceCompany = ref('');
    const GITinsuranceStart = ref('');
    const VITStart = ref('');
    const driverSex = ref('');
    const driverFullName = ref('');
    const driverDoB = ref('');
    const driverPhone = ref('');
    const driverLicenseType = ref('');
    const driverLicenseNumber = ref('');
    const driverLicenseExpiry = ref('');
    const driverEmail = ref('');
    const addressGPS = ref('');
    const addressPostal = ref('');
    const driverRegion = ref('');
    const dateResults = ref('');
    const dobError = ref('');
    const dobErrorStyle = ref('');
    const licenseError = ref('');
    const licenseErrorStyle = ref('');
    const insuranceError = ref('');
    const insuranceErrorStyle = ref('');
    const motError = ref('');
    const motErrorStyle = ref('');
    const minDate = ref('');
    const maxDate = ref('');
    const newDay = ref('');
    const newMonth = ref('');
    // Progress
    const progressBar = ref('');
    // const endMonth = ref('');
    // const endDay = ref('');
    const imageFiles = ref([]);
    const dobMaxYear = ref([]);
    const driverData = ref('');
    const docName = ref(null);
    // Date picker
    const dobDatePicker = ref(null);
    const licenseDatePicker = ref(null);
    const insuranceStartDatePicker = ref(null);
    const motStartDatePicker = ref(null);
    const GITStartDatePicker = ref(null);
    const VITStartDatePicker = ref(null);

    const getDate = () => {
      /**
     * Function to get minimum and maximum dates for selection
     */
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      // const date = new Date(year - 1, month, day);

      if (day < 10) {
        newDay.value = `0${day}`;
      } else {
        newDay.value = day;
      }
      if (month < 10) {
        newMonth.value = `0${month}`;
      } else {
        newMonth.value = month;
      }
      minDate.value = `${year - 1}-${newMonth.value}-${newDay.value}`;
      maxDate.value = `${year + 1}-${newMonth.value}-${newDay.value}`;
      dobMaxYear.value = `${year - 19}-${newMonth.value}-${newDay.value}`;
    };
    // End
    const getEndDate = (date, months) => {
    /**
     * Get End date from Start Date
     */
      const newDate = new Date(date);
      const d = newDate.getDate();
      newDate.setMonth(newDate.getMonth() + +months);
      if (newDate.getDate() !== d) {
        newDate.setDate(0);
      }
      return newDate;
    };
    // End

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      // console.log(e.target.name);
      imageFiles.value = [...imageFiles.value,
        { fieldName: e.target.id, fileData: e.target.files },
      ];
      // console.log(imageFiles.value);
    };

    const uploadFile = (imageFile, imageName) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `images/${transporterId.value}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressBar.value = uplodPercent;
      },
      (errors) => { console.log(errors.message); },
      () => { });
      // storageRef.snapshot.ref.getDownloadURL().then((url) => {
      //   // imageURL.value = url;
      //   // console.log(url);
      //   const dumped = ref('');
      //   dumped.value = url;
      // })
      // );
    };

    const getVehicleCategories = async () => {
      try {
        const res = await pentermFirestore.collection('vehicleCategories').where('status', '==', 1).orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((vehicleCat) => (
              { ...vehicleCat.data(), id: vehicleCat.id }
            ));
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    getVehicleCategories();

    // Get the list of make year from current year
    const currentDate = new Date();
    const currentDateYear = currentDate.getFullYear() + 1;
    for (let year = (currentDateYear - 12); year < currentDateYear; year += 1) {
      makeYear.value.push(year);
    }

    const getNumberOfDays = (start, end, type) => {
      const date1 = new Date(start);
      const date2 = new Date(end);
      const today = new Date();
      // const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const age = Math.round(diffInTime / oneDay / 365);
      // const months = Math.round(diffInTime / oneDay / 30);

      // Errors
      // Dob Error
      if (type === 'DoB' && age < 18) {
        dobError.value = 'Driver not old enough';
        dobErrorStyle.value = 'date-error';
      } else {
        dobError.value = '';
        dobErrorStyle.value = '';
      }
      // License Expiry Error
      if (type === 'License' && age < 2) {
        licenseError.value = 'Driver license is less than 2 years';
        licenseErrorStyle.value = 'date-error';
      } else {
        licenseError.value = '';
        licenseErrorStyle.value = '';
      }
      /**
       * Insurance Expiry Error
       * Expiry date must not be in the past
       */
      if (type === 'Insurance' && today > date2) {
        insuranceError.value = 'Insurance is not valid';
        insuranceErrorStyle.value = 'date-error';
      } else {
        insuranceError.value = '';
        insuranceErrorStyle.value = '';
      }
      /**
       * Mot Expiry Error
       * Expiry date must not be in the past
       */
      if (type === 'MoT') {
        if (today > date2 && date2 > date1) {
          motError.value = 'Road Worthy is not valid';
          motErrorStyle.value = 'date-error';
        } else if (date2 <= date1) {
          motError.value = 'Road Worthy is not valid';
          motErrorStyle.value = 'date-error';
        }
      }
    };

    // Submit data
    const submitData = async () => {
      const addData = {
        categoryName: categoryPicked.value,
        vehicleMake: vehicleMake.value,
        vehicleReg: vehicleReg.value,
        truckId: vehicleReg.value,
        vehicleVIN: vehicleVIN.value,
        vehicleMakeYear: vehicleMakeYear.value,
        insuranceCompany: insuranceCompany.value,
        insuranceType: insuranceType.value,
        insuranceStart: new Date(insuranceStart.value),
        insuranceEnd: getEndDate(insuranceStart.value, 11),
        motStart: new Date(motStart.value),
        motEnd: getEndDate(motStart.value, 11),
        goodsInTransitInsuranceCompany: GITinsuranceCompany.value,
        goodsInTransitInsuranceStart: new Date(GITinsuranceStart.value),
        goodsInTransitInsuranceEnd: getEndDate(GITinsuranceStart.value, 11),
        vehiclIncomeTaxStart: new Date(VITStart.value),
        vehiclIncomeTaxEnd: getEndDate(VITStart.value, 2),
        ownerFullName: getCompanyName.value,
        ownerEmail: user.value.email,
        uid: user.value.uid,
        transporterId: transporterId.value,
        transporterEmail: user.value.email,
        transporterName: getCompanyName.value,
        status: 1,
        jobStatus: 0,
        createdDate: timestamp(),
        updatedDate: timestamp(),
      };
      await pentermFirestore.collection('transporterVehicles').add(addData)
        .then((doc) => {
          // console.log(doc.id);
          const updateVehicleRef = pentermFirestore.collection('transporterVehicles').doc(doc.id);
          /**
           * Bulk upload files
           */
          const images = ref([]);
          imageFiles.value.forEach((data) => {
            const imageName = `${uuidv4()}.jpeg`;
            images.value = [...images.value, { docType: data.fieldName, imageFile: imageName }];
            uploadFile(data.fileData[0], imageName);
            // console.log(images.value);
            const dataFieldName = data.fieldName;
            updateVehicleRef.update({
              [dataFieldName]: imageName,
            });
          });
        });

      // Add user to team
      transporterUsers.value = [...transporterUsers.value, {
        fullName: driverFullName.value, email: driverEmail.value, role: 'driver',
      }];
      const addUserRef = pentermFirestore.collection('accountTypeACL').doc(transporterUid.value);
      addUserRef.update({
        users: transporterUsers.value,
      }, ({ merge: true }));
      // End
      /** End */
      router.push({ name: 'TransporterVehicles' });
    };
    // submitData();
    if (!fberror.value) {
      // console.log('Added to db');
      // Clear fields after submission

      // Redirect after adding data
      // router.push({ name: 'Transporters' });
    }
    // end

    const uploadImages = () => {
      /**
       * Bulk upload files
       */
      const images = ref([]);
      imageFiles.value.forEach((data) => {
        // console.log(data[0]);
        const imageName = `${uuidv4()}.jpeg`;
        images.value = [...images.value, imageName];
        uploadFile(data.fileData[0], imageName);
        // console.log(images.value);
      });
    };

    // Check if driver assigned already exist
    const getdriverData = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles').where('driverPhone', '==', driverPhone.value)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            driverData.value = snap.docs.map((driver) => (
              { ...driver.data(), id: driver.id }
            ));
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };

    onMounted(() => {
      getDate();
      // insurance picker
      flatpickr(insuranceStartDatePicker.value, {
        minDate: minDate.value,
        maxDate: maxDate.value,
        allowInput: true,
      });
      // mot picker
      flatpickr(motStartDatePicker.value, {
        minDate: minDate.value,
        maxDate: maxDate.value,
      });
      // GIT picker
      flatpickr(GITStartDatePicker.value, {
        minDate: minDate.value,
        maxDate: maxDate.value,
        allowInput: true,
      });
      // VIT picker
      flatpickr(VITStartDatePicker.value, {
        minDate: minDate.value,
        maxDate: maxDate.value,
        allowInput: true,
      });
      if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });

    return {
      user,
      getCompanyName,
      docName,
      driverData,
      getdriverData,
      minDate,
      maxDate,
      dobMaxYear,
      getEndDate,
      router,
      getNumberOfDays,
      submitData,
      dobError, // dob error
      dobErrorStyle, // css
      licenseError,
      licenseErrorStyle, // css
      motError,
      motErrorStyle, // css
      insuranceError,
      insuranceErrorStyle,
      dateResults,
      driverLicenseType,
      driverLicenseNumber,
      driverLicenseExpiry,
      driverRegion,
      dataResults,
      dump,
      error,
      publicPath,
      categoryPicked,
      makeYear,
      vehicleMake,
      vehicleReg,
      vehicleVIN,
      vehicleMakeYear,
      insuranceCompany,
      insuranceType,
      insuranceStart,
      insuranceEnd,
      motStart,
      motEnd,
      GITinsuranceCompany,
      GITinsuranceStart,
      VITStart,
      driverSex,
      driverFullName,
      driverDoB,
      driverPhone,
      driverEmail,
      addressGPS,
      addressPostal,
      fileUpload,
      uploadImages,
      progressBar,
      insuranceStartDatePicker,
      motStartDatePicker,
      GITStartDatePicker,
      VITStartDatePicker,
      licenseDatePicker,
      dobDatePicker,

    };
  },
});
</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.form-stack {
  display: block;
}
.field-margin {
  margin: auto;
  padding: 10px;
}
.page-header-border {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 5px;
}
.page-header {
  margin: 10px auto;
  border-right: 4px solid tomato;
  padding: 10px;
}
.file-input {
  display: none;
}
.hide-imput {
  padding: 1px;
}
.hide-imput input{
  display: none;
}
.date-error {
  border: 1px solid tomato;
}
.required-field{
  color: #cc0000;
}

</style>
