<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Overview</h6>

            <!-- Title -->
            <h1 class="header-title">Fleet</h1>
          </div>
          <!--  -->
          <div class="col-auto">
            <!-- Button -->
            <router-link replace
              class="btn btn-primary lift"
              :to="{ name: 'NewTransporterVehicle' }"
            >
            Add Company Vehicle
            </router-link>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <router-link replace
              class="btn btn-primary lift"
              :to="{ name: 'VehicleOwners' }"
            >
            Add Owner Vehicle
            </router-link>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid" v-if="dataResults">
    <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Total</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 200 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-truck text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Hours -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Insurance Due</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 100 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-primary mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Exit -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Mot Due</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 50 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-warning mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">
                  Due for Maintenace
                </h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 10 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-trash text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- Job list below -->
    <div class="row">
      <div class="col-12">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Fleet</h4>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <!-- <a href="#!" class="btn btn-sm btn-white"> Add </a> -->
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div class="table-responsive mb-0">
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a href="#" class="text-muted"> Owner </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> License Plate </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> Driver </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> Inusrance Expiry </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> MoT Expiry </a>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="vehicle in dataResults" :key="vehicle.id">
                  <td class="goal-project">{{ vehicle.ownerFullName }}</td>
                  <td class="goal-status">
                    {{ vehicle.vehicleReg }}
                  </td>
                  <td class="goal-progress">
                    {{ vehicle.driverFullName }}
                  </td>
                  <td class="goal-date">
                    <span
                      style="font-size: 1.2rem"
                      v-if="vehicle.insuranceEnd.toDate() < today"
                    >
                      <span style="color: Red">
                        <i class="fas fa-times-circle px-1"></i>
                      </span>
                    </span>
                    <span style="font-size: 1.2rem" v-else>
                      <span style="color: Green">
                        <i class="far fa-check-circle px-1"></i>
                      </span>
                    </span>
                    {{ vehicle.insuranceEnd.toDate().toDateString() }}
                  </td>
                  <td class="text-start">
                    <span
                      style="font-size: 1.2rem"
                      v-if="vehicle.motEnd.toDate() <= today"
                    >
                      <span style="color: Red">
                        <i class="fas fa-times-circle px-1"></i>
                      </span>
                    </span>
                    <span style="font-size: 1.2rem" v-else>
                      <span style="color: Green">
                        <i class="far fa-check-circle px-1"></i>
                      </span>
                    </span>
                    {{ vehicle.motEnd.toDate().toDateString() }}
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'TransporterVehicleEdit',
                            params: { id: vehicle.id },
                          }"
                        >
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </router-link>
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'TransporterVehicleEdit',
                            params: { id: vehicle.id },
                          }"
                        >
                          <span class="fe fe-map-pin text-muted mb-0"></span>
                          Track
                        </router-link>
                        <a href="#" class="dropdown-item" @click="deleteVehicle(vehicle.id)">
                           <span class="fe fe-trash-2 text-muted mb-0"></span> Delete
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import firebase from 'firebase';
import { ref, onMounted } from '@vue/runtime-dom';
// import { useRouter } from 'vue-router';
import getUser from '../../composables/getUser';
import { pentermFirestore, timestamp } from '../../firebase/config';

export default {
  name: '',
  props: [''],
  setup() {
    const { user } = getUser();
    // const router = useRouter();
    const loggedInUser = ref('');
    const error = ref('');
    const dataResults = ref([]);
    const dump = ref('');
    // let last;
    const records = ref('');
    const disableBtn = ref('');
    // const fileName = ref('');
    const today = ref('');

    today.value = new Date();
    // end
    const vehicles = async () => {
      const insuranceReminder = async (
        id, email, vehicleReg, reminderType, days, date, company) => {
        const mailContent = {
          to: email,
          message: {
            subject: `Insurance Renewal: ${vehicleReg}`,
            html: `<p>Hello Client</p>,
            <p>Your vehicle with registration number ${vehicleReg} is ${days}days away from ${reminderType} renewal.</p>
            <p>Expiry date: ${date}</p>
            <p><Insurance Company: ${company}/p>
            <p>Please renew soon.</p>
            <p></p>
            <p>Thank You<br>Penterm Reminders</p>`,
          },
        };
        // console.log(mailContent);
        await pentermFirestore.collection('mail').add(mailContent);
        if (days <= 0) {
          const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(id);
          await vehicleRef.update({
            status: 0,
            updatedDate: timestamp(),
          });
        }
      };
      try {
        const res = await pentermFirestore
          .collection('transporterVehicles')
          .orderBy('createdDate', 'desc')
          .get().then((snap) => {
            console.log(snap);
            snap.docs.forEach((data) => {
              const oneDay = 1000 * 60 * 60 * 24;
              // Get info for email
              const uid = data.id;
              const email = data.data().transporterEmail;
              const { vehicleReg } = data.data();
              let reminderType = '';
              const endDate = data.data().insuranceEnd.toDate();
              const company = data.data().insuranceCompany;
              // End

              // Insurance Renewal
              const insuranceEnd = (
                new Date(data.data().insuranceEnd.toDate()).getTime());
              const difference = insuranceEnd - Date.now();
              // this will be in milliseconds
              const insuranceState = '';

              // Insurance Renewals
              const insuranceDaysRemaining = Math.round(difference / oneDay);
              if (insuranceDaysRemaining === 30) {
                reminderType = 'Vehicle Insurance';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, insuranceDaysRemaining, endDate, company,
                );
              }
              if (insuranceDaysRemaining === 14) {
                reminderType = 'Vehicle Insurance';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, insuranceDaysRemaining, endDate, company,
                );
              }
              if (insuranceDaysRemaining > 0 && insuranceDaysRemaining < 7) {
                reminderType = 'Vehicle Insurance';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, insuranceDaysRemaining, endDate, company,
                );
              }
              if (insuranceDaysRemaining <= 0) {
                reminderType = 'Vehicle Insurance';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, insuranceDaysRemaining, endDate, company,
                );
              }
              /**
                 * Roadworthy Renewal
                 */
              // Roadworthy Renewal
              const motEnd = (
                new Date(data.data().motEnd.toDate()).getTime());
              const motDaysDiff = motEnd - Date.now();
              // this will be in milliseconds

              // Insurance Renewals
              const motDaysRemaining = Math.round(motDaysDiff / oneDay);
              if (motDaysRemaining === 30) {
                reminderType = 'Road Worthy/MOT';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, motDaysRemaining, endDate, company,
                );
              }
              if (motDaysRemaining === 14) {
                reminderType = 'Road Worthy/MOT';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, motDaysRemaining, endDate, company,
                );
              }
              if (motDaysRemaining > 0 && motDaysRemaining < 7) {
                reminderType = 'Road Worthy/MOT';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, motDaysRemaining, endDate, company,
                );
              }
              if (motDaysRemaining <= 0) {
                reminderType = 'Road Worthy/MOT';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, motDaysRemaining, endDate, company,
                );
              }
              /**
                 * Vehicle Income Tax Renewals
                 */
              // Vehicle Income Tax Renewal
              const VITEnd = (
                new Date(data.data().vehiclIncomeTaxEnd.toDate()).getTime());
              const VITDaysDiff = VITEnd - Date.now();
              // this will be in milliseconds

              // Vehicle Income Tax Renewal
              const VITDaysRemaining = Math.round(VITDaysDiff / oneDay);
              if (VITDaysRemaining === 30) {
                reminderType = 'Vehicle Income Tax';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, VITDaysRemaining, endDate, company,
                );
              }
              if (VITDaysRemaining === 14) {
                reminderType = 'Vehicle Income Tax';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, VITDaysRemaining, endDate, company,
                );
              }
              if (VITDaysRemaining > 0 && VITDaysRemaining < 7) {
                reminderType = 'Vehicle Income Tax';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, VITDaysRemaining, endDate, company,
                );
              }
              if (VITDaysRemaining <= 0) {
                reminderType = 'Vehicle Income Tax';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, VITDaysRemaining, endDate, company,
                );
              }
              /**
                 * Goods In Transit Renewals
                 */
              // Vehicle Income Tax Renewal
              const GITEnd = (
                new Date(data.data().goodsInTransitInsuranceEnd
                  .toDate()).getTime());
              const GITDaysDiff = GITEnd - Date.now();
              // this will be in milliseconds

              // Vehicle Income Tax Renewal
              const GITDaysRemaining = Math.round(GITDaysDiff / oneDay);
              if (GITDaysRemaining === 30) {
                reminderType = 'Goods In-Transit Insurance';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, GITDaysRemaining, endDate, company,
                );
              }
              if (GITDaysRemaining === 14) {
                reminderType = 'Goods In-Transit Insurance';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, GITDaysRemaining, endDate, company,
                );
              }
              if (GITDaysRemaining > 0 && GITDaysRemaining < 7) {
                reminderType = 'Goods In-Transit Insurance';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, GITDaysRemaining, endDate, company,
                );
              }
              if (GITDaysRemaining <= 0) {
                reminderType = 'Goods In-Transit Insurance';
                insuranceReminder(
                  uid, email, vehicleReg,
                  reminderType, GITDaysRemaining, endDate, company,
                );
              }
              const insuranceDetails = {
                vehicle: data.data().vehicleReg,
                insuranceDays: insuranceDaysRemaining,
                motDays: motDaysRemaining,
                VITDays: VITDaysRemaining,
                GITDays: GITDaysRemaining,
                status: insuranceState,
              };
              console.log(insuranceDetails);
            });
            // const getEndTime = (new Date(snap.docs.data().insuranceEnd).getTime());
            dataResults.value = snap.docs.map((vehicle) => ({
              ...vehicle.data(),
              id: vehicle.id,
            }));
            // last = snap.docs[snap.docs.length - 1];
            records.value = snap.docs.length;
            // console.log(last.data());
            disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };

    // delete vehicles
    const deleteVehicle = async (id) => {
      await pentermFirestore.collection('transporterVehicles').doc(id).delete();
    };
    vehicles();

    onMounted(() => {
      // vehicles();
      // if (userDetails.value.accessType === 'driver') {
      //   router.push({ name: 'TransporterDriverJobs' });
      // }
    });

    return {
      user,
      today,
      loggedInUser,
      dataResults,
      records,
      disableBtn,
      deleteVehicle,
    };
  },
};
</script>
<style>
</style>
