<template>
<!--  -->
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Driver Update</h6>

            <!-- Title -->
            <h1 class="header-title">{{driverFullName}}</h1>
            <div>
              Date of Birth: <span class="text-muted"> {{ driverDoB }}</span>
              License No: <span class="text-primary"> {{ driverLicenseNumber }}</span>
            </div>
          </div>
          <div class="col-auto" v-if="editDriver">
            <!-- Button -->
            <a href="#!" class="btn btn-secondary lift" @click="showDriverDetails"> Cancel </a>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
    <!-- Alert -->
    <div class="alert alert-info alert-dismissible fade show"
    role="alert" v-if="showSuccessAlert">
      <strong>{{showSuccessMessage}}!</strong> Click close to dismiss this message.

      <!-- Button -->
      <button type="button" class="btn-close"
      data-bs-dismiss="alert" aria-label="Close"></button>

    </div>
        <!-- .end of alert -->
  <!-- Driver Form -->
        <div class="row">
          <!-- Driver Details -->
            <div class="col-4">
<div class="card card-fill">
                  <!-- Body -->
                  <div class="card-body text-center">

                    <!-- Image -->
                    <div class="avatar avatar-xxl">
  <img :src="driverLicensePhotoA" class="avatar-img rounded-circle">
</div>
                    <!-- <a href="#" class="avatar avatar-xl mb-3">
                     <span class="h1 fe fe-user"></span>
                    </a> -->

                    <!-- Heading -->
                    <h2 class="card-title">
                      {{ driverFullName }}
                    </h2>

                    <!-- Text -->
                    <!-- <p class="small text-muted mb-3">
                      Woprking on the latest API integration.
                    </p> -->

<!-- List group -->
                    <div class="list-group list-group-flush mb-4">
                      <div class="list-group-item">
                        <div class="row align-items-center">
                          <div class="col text-start">

                            <!-- Title -->
                            <small>Personal Details</small>

                          </div>
                          <div class="col-auto text-start small">

                            <span class="text-muted">
                              Date of Birth: {{ driverDoB }}<br>
                              {{ driverEmail }}<br>
                              {{ driverPhone }}
                            </span>

                          </div>
                        </div> <!-- / .row -->
                      </div>
                      <div class="list-group-item">
                        <div class="row align-items-center">
                          <div class="col text-start">

                            <!-- Title -->
                            <small>License Details</small>

                          </div>
                          <div class="col-auto small text-start">

                            <!-- Text -->
                            <small class="text-muted">
                              Category: {{driverLicenseType}}<br>
                              Number: {{ driverLicenseNumber }}<br>
                              Expiry: {{ driverLicenseExpiry }}<br>
                            </small>

                          </div>
                        </div> <!-- / .row -->
                      </div>
                      <div class="list-group-item">
                        <div class="row align-items-center">
                          <div class="col text-start">

                            <!-- Title -->
                            <small>Address</small>

                          </div>
                          <div class="col-auto text-start">

                            <!-- Link -->
                            <span class="small text-muted">
                              Ghana Post Address: {{postCode}}<br>
                              Postal Address: {{ addressPostal }}<br>
                              Region: {{ driverRegion }}<br>
                            </span>

                          </div>
                        </div> <!-- / .row -->
                      </div>
                      <div class="list-group-item">
                        <div class="row align-items-center">
                          <div class="col text-start">

                            <!-- Title -->
                            <small>Government Identification </small>

                          </div>
                          <div class="col-auto text-start">

                            <small>
                              <button type="button"
                              @click="getImageURL(`images/${transporterId}/${governmentID}`)"
                              class="btn btn-sm btn-white" data-bs-toggle="modal"
                              data-bs-target="#imagePreviewModal">
                                View
                              </button>
                            </small>

                          </div>
                        </div> <!-- / .row -->
                      </div>
                      <div class="list-group-item">
                        <div class="row align-items-center">
                          <div class="col text-start">

                            <!-- Title -->
                            <small>Drivers License</small>

                          </div>
                          <div class="col-auto text-start">

                            <small>
                              <button type="button"
                              @click="getImageURL(`images/${transporterId}/${driverLicensePhotoA}`)"
                              class="btn btn-sm btn-white" data-bs-toggle="modal"
                              data-bs-target="#imagePreviewModal">
                                View Front
                              </button>
                            </small>

                          </div>
                          <div class="col-auto text-start">

                            <small>
                              <button type="button"
                              @click="getImageURL(`images/${transporterId}/${driverLicensePhotoB}`)"
                              class="btn btn-sm btn-white" data-bs-toggle="modal"
                              data-bs-target="#imagePreviewModal">
                                View Back
                              </button>
                            </small>

                          </div>
                        </div> <!-- / .row -->
                      </div>
                    </div>

                  </div>
                        <!-- <small>
                          <button type="button"
                          @click="getImageURL(`images/${transporterId}/${driverLicensePhotoA}`)"
                          class="btn btn-sm btn-white" data-bs-toggle="modal"
                          data-bs-target="#imagePreviewModal">
                            View Certificate
                          </button>
                        </small> -->
                </div>
                </div>
      <!-- .end -->
      <div class="col-8">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
<!--  -->
     <form @submit.prevent="updateVehicle" autocomplete="off" class="py-2">
       <div class="row error">
       </div>
       <h4 class="text-primary text-start text-danger">Driver Details</h4>
        <div class="row text-start py-2">
          <!--  -->
          <div class="col-xs-12 col-md-2">
            <label>Sex</label>
              <select class="form-control" required v-model="driverSex">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-4 text-start">
            <label>Driver Full Name</label>
              <input type="text" class="form-control"
              placeholder="Kofi Manu" required v-model="driverFullName">
            </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Date Of Birth</label>
              <input type="text" class="form-control"
              placeholder="DoB" required v-model="driverDoB"
              ref="dobDatePicker"
              @change="getNumberOfDays(driverDoB, new Date(),'DoB')">
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Phone Number</label>
              <input type="tel" class="form-control"
              placeholder="024412345678" required v-model="driverPhone">
          </div>
          <!--  -->
          <!--  -->
          <!-- end of row -->
        </div>
        <div class="row text-start py-2">
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Email</label>
              <input type="email" class="form-control" disabled
              placeholder="[optional]" v-model="driverEmail">
          </div>
          <!--  -->
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >License Class</label>
              <select class="form-control" required v-model="driverLicenseType">
                <!-- <option value="B">Class B</option> -->
                <option value="F">Class F</option>
              </select>
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >License Number</label>
              <input type="text" class="form-control"
              placeholder="License number" required v-model="driverLicenseNumber">
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >License Expiry</label>
              <input type="text" class="form-control"
              placeholder="Expiry date" required v-model="driverLicenseExpiry"
              ref="licenseDatePicker"
              @change="getNumberOfDays(new Date(), driverLicenseExpiry, 'License')">
          </div>
          <!-- End of Row -->
        </div>
        <div class="row text-start py-2">
          <div class="col-xs-12 col-md-3">
            <label >GhanaPost Address</label>
              <input type="text" class="form-control"
              placeholder="GA-123-5432" required v-model="postCode">
          </div>
          <div class="col-xs-12 col-md-3">
            <label >Post Box [optional]</label>
              <input type="text" class="form-control"
              placeholder="P.O. Box 1" v-model="addressPostal">
          </div>
          <!--  -->
          <div class="col-xs-12 col-md-3">
              <label >Region</label>
              <select class="form-control"
              placeholder="Region" required v-model="driverRegion">
                <option value="">Select</option>
                <option value="Oti Region">Oti Region</option>
                <option value="Bono East Region">Bono East Region</option>
                <option value="Ahafo Region">Ahafo Region</option>
                <option value="Bono Region">Bono Region</option>
                <option value="North East  Region">North East Region</option>
                <option value="Savannah Region">Savannah Region</option>
                <option value="Western North Region">Western North Region</option>
                <option value="Western Region">Western Region</option>
                <option value="Volta Region">Volta Region</option>
                <option value="Greater Accra Region ">Greater Accra Region </option>
                <option value="Eastern Region">Eastern Region</option>
                <option value="Ashanti Region">Ashanti Region</option>
                <option value="Northern Region">Northern Region </option>
                <option value="Central Region">Central Region</option>
                <option value="Upper East Region">Upper East Region</option>
                <option value="Upper West Region">Upper West Region </option>
              </select>
          </div>
        </div>
        <!-- Row -->
        <!--  -->
    <div class="row py-2 text-start">
    </div>
    <h4 class="text-primary text-start text-danger">
      Required Documents [ All Must images accepted formats *.png,*.jpg,*jpeg ]
      </h4>
          <div class="row">
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Driver Photo
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="driverPhoto" name="Driver Photo" required
                  capture="user" accept="image/*" @change="fileUpload">
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Government Identification
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="govenmentID" name="Govenment ID" required
                  capture="user" accept="image/*" @change="fileUpload">
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Drivers License Front
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="driverLicensePhotoA" name="Drivers License Front"
                  capture="user" accept="image/*" @change="fileUpload" required>
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Drivers License Back
                <span class="required-field"> *</span>
              </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="driverLicensePhotoB" name="Drivers License Back"
                  capture="user" accept="image/*" @change="fileUpload" required>
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              &nbsp;
            </div>
            <div class="col-xs-4 col-md-3">
              &nbsp;
            </div>
            <!--  -->
            </div>
      <!-- Submit -->
              <div class="row">
          <div class="col-xs-12 col-md-12"><span class="error">{{ error }}</span></div>
          <div class="col-xs-12 col-md-12 text-center">
            <button class="btn btn-danger">Update</button>
          </div>
        </div>
    <!-- form end -->
      </form>
          </div>
        </div>
      </div>
  </div>
<!-- Modal -->
<div class="modal fade"
id="imagePreviewModal" tabindex="-1"
aria-labelledby="imagePreviewModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="imagePreviewModalLabel">Document Preview</h5>
        <button type="button"
        class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
          <img :src="imageURL" class="img-thumbnail py-2">
      </div>
      <div class="modal-footer">
        <button type="button"
        class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <a :href="imageURL" download target="_blank">
        <button type="button" class="btn btn-primary">Download</button>
        </a>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import {
  ref, onMounted,
} from 'vue';
import flatpickr from 'flatpickr';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase';
import { useRoute, useRouter } from 'vue-router';
import getUser from '../../composables/getUser';
import { timestamp, pentermFirestore } from '../../firebase/config';

export default ({
  components: {
  },
  setup() {
    const { user, getUserRole, transporterId } = getUser();
    const router = useRouter();
    const route = useRoute();
    // const fberror = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const error = ref('');
    const publicPath = process.env.BASE_URL;
    const driverSex = ref('');
    const driverFullName = ref('');
    const driverDoB = ref('');
    const driverPhone = ref('');
    const driverLicenseType = ref('');
    const driverLicenseNumber = ref('');
    const driverLicenseExpiry = ref('');
    const driverEmail = ref('');
    const postCode = ref('');
    const addressPostal = ref('');
    const driverRegion = ref('');
    const dateResults = ref('');
    const dobError = ref('');
    const dobErrorStyle = ref('');
    const licenseError = ref('');
    const licenseErrorStyle = ref('');
    const insuranceError = ref('');
    const insuranceErrorStyle = ref('');
    const motError = ref('');
    const motErrorStyle = ref('');
    const userGuard = ref('');
    const minDate = ref('');
    const maxDate = ref('');
    const newDay = ref('');
    const newMonth = ref('');
    const imageFiles = ref([]);
    const dobMaxYear = ref([]);
    const editDriver = ref(false);
    // alerts
    const showSuccessAlert = ref(false);
    const showSuccessMessage = ref('');
    // Date picker
    const dobDatePicker = ref(null);
    const licenseDatePicker = ref(null);
    // image
    const imageURL = ref('');
    const driverLicensePhotoA = ref('');
    const driverLicensePhotoB = ref('');
    const driverPhoto = ref('');
    const governmentID = ref('');

    // Function to format dates
    const formatDate = (date) => {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) { month = `0${month}`; }
      if (day.length < 2) { day = `0${day}`; }

      return [year, month, day].join('-');
    };

    const showDriverDetails = () => {
      editDriver.value = !editDriver.value;
    };

    const getDate = () => {
      /**
     * Function to get minimum and maximum dates for selection
     */
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      // const date = new Date(year - 1, month, day);

      if (day < 10) {
        newDay.value = `0${day}`;
      } else {
        newDay.value = day;
      }
      if (month < 10) {
        newMonth.value = `0${month}`;
      } else {
        newMonth.value = month;
      }
      minDate.value = `${year - 1}-${newMonth.value}-${newDay.value}`;
      maxDate.value = `${year + 1}-${newMonth.value}-${newDay.value}`;
      dobMaxYear.value = `${year - 19}-${newMonth.value}-${newDay.value}`;
    };
    // End

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      // console.log(e.target.name);
      imageFiles.value = [...imageFiles.value,
        { fieldName: e.target.id, fileData: e.target.files },
      ];
      // console.log(imageFiles.value);
    };

    const uploadFile = (imageFile, imageName) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `images/${transporterId.value}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(uplodPercent);
      },
      (errors) => { console.log(errors.message); },
      () => { },
      storageRef.snapshot.ref.getDownloadURL().then((url) => {
        // imageURL.value = url;
        // console.log(url);
        const dumped = ref('');
        dumped.value = url;
      }));
    };

    // Get vehicle selected for editing
    const getDriver = async () => {
      try {
        const res = await pentermFirestore.collection('transporterDrivers').doc(route.params.id).get();
        if (!res.exists) {
          throw Error('That trip does not exists');
        }
        // end
        driverFullName.value = res.data().driverFullName;
        driverSex.value = res.data().driverSex;
        driverDoB.value = res.data().driverDoB;
        driverPhone.value = res.data().driverPhone;
        driverEmail.value = res.data().driverEmail;
        postCode.value = res.data().postCode;
        addressPostal.value = res.data().addressPostal;
        driverRegion.value = res.data().driverRegion;
        driverLicenseType.value = res.data().driverLicenseType;
        driverLicenseNumber.value = res.data().driverLicenseNumber;
        driverLicenseExpiry.value = formatDate(res.data().driverLicenseExpiry.toDate());
        driverRegion.value = res.data().driverRegion;
        userGuard.value = res.data().uid;
        driverLicensePhotoA.value = res.data().driverLicensePhotoA;
        driverLicensePhotoB.value = res.data().driverLicensePhotoB;
        driverPhoto.value = res.data().driverPhoto;
        governmentID.value = res.data().govenmentID;
        // Insurance details
      } catch (err) {
        error.value = err.message;
      }
    };

    const getNumberOfDays = (start, end, type) => {
      const date1 = new Date(start);
      const date2 = new Date(end);
      const today = new Date();
      // const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const age = Math.round(diffInTime / oneDay / 365);
      // const months = Math.round(diffInTime / oneDay / 30);

      // Errors
      // Dob Error
      if (type === 'DoB' && age < 18) {
        dobError.value = 'Driver not old enough';
        dobErrorStyle.value = 'date-error';
      } else {
        dobError.value = '';
        dobErrorStyle.value = '';
      }
      // License Expiry Error
      if (type === 'License' && age < 2) {
        licenseError.value = 'Driver license is less than 2 years';
        licenseErrorStyle.value = 'date-error';
      } else {
        licenseError.value = '';
        licenseErrorStyle.value = '';
      }
      /**
       * Insurance Expiry Error
       * Expiry date must not be in the past
       */
      if (type === 'Insurance' && today > date2) {
        insuranceError.value = 'Insurance is not valid';
        insuranceErrorStyle.value = 'date-error';
      } else {
        insuranceError.value = '';
        insuranceErrorStyle.value = '';
      }
      /**
       * Mot Expiry Error
       * Expiry date must not be in the past
       */
      if (type === 'MoT') {
        if (today > date2 && date2 > date1) {
          motError.value = 'Road Worthy is not valid';
          motErrorStyle.value = 'date-error';
        } else if (date2 <= date1) {
          motError.value = 'Road Worthy is not valid';
          motErrorStyle.value = 'date-error';
        }
      }
    };

    // Submit update
    const updateVehicle = async () => {
      // ensure only the specified user can edit the data
      /**
       * Upload images
       */
      const images = ref([]);
      imageFiles.value.forEach((data) => {
        const imageName = `${uuidv4()}.jpg`;
        images.value = [...images.value, imageName];
        uploadFile(data[0], imageName);
      });
      // end of upload
      if (user.value.uid === userGuard.value) {
        const vehicleRef = pentermFirestore.collection('transporterDrivers').doc(route.params.id);
        await vehicleRef.update({
          driverSex: driverSex.value,
          driverFullName: driverFullName.value,
          driverDoB: driverDoB.value,
          postCode: postCode.value,
          addressPostal: addressPostal.value,
          driverPhone: driverPhone.value,
          driverRegion: driverRegion.value,
          driverLicenseType: driverLicenseType.value,
          driverLicenseNumber: driverLicenseNumber.value,
          driverLicenseExpiry: new Date(driverLicenseExpiry.value),
          updatedDate: timestamp(),
          updatedBy: user.value.displayName,
        });
        // Upload image
        imageFiles.value.forEach((data) => {
          const imageName = `${uuidv4()}.jpeg`;
          uploadFile(data.fileData[0], imageName);
          // console.log(images.value);
          const dataFieldName = data.fieldName;
          vehicleRef.update({
            [dataFieldName]: imageName,
          });
        });
        showSuccessMessage.value = 'Driver Updated';
        showSuccessAlert.value = true;
        router.push({ name: 'Drivers' });
      } else {
        router.push({ name: 'Error' });
      }
    };

    getDriver();
    const getImageURL = (imagePath) => {
      const storageRef = firebase.storage().ref();
      storageRef.child(imagePath).getDownloadURL()
        .then((url) => { imageURL.value = url; });
      // console.log(imageURL.value);
    };

    onMounted(() => {
      getDate();
      // License picker
      flatpickr(licenseDatePicker.value, {
        minDate: 'today',
      });
      // License picker
      flatpickr(dobDatePicker.value, {
        maxDate: dobMaxYear.value,
        allowInput: true,
      });
      if (getUserRole.value === 'admin') {
        getDriver();
      } else if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });

    return {
      user,
      transporterId,
      router,
      minDate,
      maxDate,
      dobMaxYear,
      fileUpload,
      getNumberOfDays,
      updateVehicle,
      dobDatePicker,
      dateResults,
      driverLicenseType,
      driverLicenseNumber,
      driverLicenseExpiry,
      driverRegion,
      dataResults,
      dump,
      error,
      publicPath,
      licenseDatePicker,
      driverSex,
      driverFullName,
      driverDoB,
      driverPhone,
      driverEmail,
      postCode,
      addressPostal,
      editDriver,
      showDriverDetails,
      // Insurance Dates
      showSuccessAlert,
      showSuccessMessage,
      imageURL,
      getImageURL,
      driverLicensePhotoA,
      driverLicensePhotoB,
      driverPhoto,
      governmentID,
    };
  },
});
</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.form-stack {
  display: block;
}
.field-margin {
  margin: auto;
  padding: 10px;
}
.page-header-border {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 5px;
}
.page-header {
  margin: 10px auto;
  border-right: 4px solid tomato;
  padding: 10px;
}
.file-input {
  display: none;
}
.hide-imput {
  padding: 1px;
}
.hide-imput input{
  display: none;
}
.date-error {
  border: 1px solid tomato;
}

</style>
