<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Form</h6>

            <!-- Title -->
            <h1 class="header-title">Driver Registration</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
                        <!-- Button -->
            <router-link replace
              class="btn btn-secondary lift"
              :to="{ name: 'Drivers' }"
            >
            Cancel
            </router-link>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->
<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <!-- Progress Bar -->
      <div class="card" v-if="progressBar">
        <div class="card-body">

          <!-- Progress -->
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="`width:${ progressBar }%`"
            aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

        </div>
      </div>
      <!--  -->
  <div class="alert alert-danger" role="alert" v-if="driverData.length > 0">
    Driver email error duplicate entry.
  </div>

  <!-- <button class="btn btn-primary" @click="uploadImages">Click me</button> -->
      <form @submit.prevent="submitData" autocomplete="off" class="py-2">
        <div class="row error" v-if="dobError || licenseError || insuranceError">
          <div class="col-xs-12 col-md-12">
            <ul>
              <li v-if="dobError">{{ dobError }}</li>
              <li v-if="licenseError">{{ licenseError }}</li>
              <li v-if="insuranceError">{{ insuranceError }}</li>
            </ul>
          </div>
        </div>
          <div class="row">
            <!--  -->
            <div class="col-xs-12 col-md-2">
              <div class="form-floating mb-2">
                <select class="form-control" required v-model="driverSex">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
                <label>Sex <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-4">
              <div class="form-floating mb-4">
                <input type="text" class="form-control"
                required v-model="driverFullName">
                <label>Driver full name <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" :class="[dobErrorStyle]"
                required v-model="driverDoB" ref="dobDatePicker"
                >
                <label>Date Of Birth <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
            <!-- if driver exists -->
              <small class="text-danger" v-if="driverData.length > 0">
                Driver Already Registered
                </small>
              <!--  -->
              <div class="form-floating mb-3">
                <input type="tel" class="form-control"  @keyup="getdriverData"
                required v-model="driverPhone">
                <label>Phone Number <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <!-- end of row -->
          </div>
          <div class="row">
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="email" class="form-control"
                v-model="driverEmail">
                <label>Email</label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control" required v-model="driverLicenseType">
                  <option value="F">Class F</option>
                </select>
                <label>License Type <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="driverLicenseNumber">
                <label>License Number <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="driverLicenseExpiry"
                :class="[licenseErrorStyle]"
                ref="licenseDatePicker"
                >
                <label>License Expiry <span class="required-field"> *</span></label>
              </div>
            </div>
            <!-- End of Row -->
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="postCode">
                <label>Post Code <span class="required-field"> *</span></label>
              </div>
            </div>
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                v-model="addressPostal">
                <label>Post Box [optional]</label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" required v-model="driverRegion">
                  <option value="">Select</option>
                  <option value="Oti Region">Oti Region</option>
                  <option value="Bono East Region">Bono East Region</option>
                  <option value="Ahafo Region">Ahafo Region</option>
                  <option value="Bono Region">Bono Region</option>
                  <option value="North East  Region">North East Region</option>
                  <option value="Savannah Region">Savannah Region</option>
                  <option value="Western North Region">Western North Region</option>
                  <option value="Western Region">Western Region</option>
                  <option value="Volta Region">Volta Region</option>
                  <option value="Greater Accra Region ">Greater Accra Region </option>
                  <option value="Eastern Region">Eastern Region</option>
                  <option value="Ashanti Region">Ashanti Region</option>
                  <option value="Northern Region">Northern Region </option>
                  <option value="Central Region">Central Region</option>
                  <option value="Upper East Region">Upper East Region</option>
                  <option value="Upper West Region">Upper West Region </option>
                </select>
                <label>Region <span class="required-field"> *</span></label>
              </div>
            </div>
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                v-model="socialSecurityNo">
                <label>Social Security Number</label>
              </div>
            </div>
          </div>
          <!-- Row -->
      <h4 class=" text-start text-danger">
        Required Documents [ All Must images accepted formats *.png,*.jpg,*jpeg ]
        </h4>
            <div class="row">
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Driver Photo
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="driverPhoto" name="Driver Photo" required
                  capture="user" accept="image/*" @change="fileUpload">
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Government Identification
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="govenmentID" name="Govenment ID" required
                  capture="user" accept="image/*" @change="fileUpload">
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Drivers License Front
                <span class="required-field"> *</span>
                </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="driverLicensePhotoA" name="Drivers License Front"
                  capture="user" accept="image/*" @change="fileUpload" required>
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              <h4 class="text-primary">
                Drivers License Back
                <span class="required-field"> *</span>
              </h4>
                <label class="py-2">
                  <input type="file" class="form-control"
                  id="driverLicensePhotoB" name="Drivers License Back"
                  capture="user" accept="image/*" @change="fileUpload" required>
              </label>
            </div>
            <div class="col-xs-4 col-md-3">
              &nbsp;
            </div>
            <div class="col-xs-4 col-md-3">
              &nbsp;
            </div>
            <!--  -->
            </div>
        <!-- Submit -->
                <div class="row">
            <div class="col-xs-12 col-md-12"><span class="error">{{ error }}</span></div>
            <div class="col-xs-12 col-md-12 text-center" v-if="driverData.length === 0">
              <button class="btn btn-danger">Submit</button>
            </div>
          </div>
      <!-- form end -->
        </form>
    </div>
  </div>
</div>
</template>

<script>
import {
  ref, onMounted,
} from 'vue';
import flatpickr from 'flatpickr';
import { useRouter } from 'vue-router';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
// import {
//   getStorage, useStorageEmulator, ref as imgRef, uploadBytes,
// } from 'firebase/storage';
import getUser from '../../composables/getUser';
import { timestamp, pentermFirestore } from '../../firebase/config';

export default ({
  components: {
  },
  setup() {
    const {
      user, transporterId, getUserRole, getCompanyName,
    } = getUser();
    const router = useRouter();
    const fberror = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const error = ref('');
    const driverSex = ref('');
    const driverFullName = ref('');
    const driverDoB = ref('');
    const driverPhone = ref('');
    const driverLicenseType = ref('');
    const driverLicenseNumber = ref('');
    const driverLicenseExpiry = ref('');
    const driverEmail = ref('');
    const postCode = ref('');
    const addressPostal = ref('');
    const driverRegion = ref('');
    const dateResults = ref('');
    const dobError = ref('');
    const dobErrorStyle = ref('');
    const licenseError = ref('');
    const licenseErrorStyle = ref('');
    const insuranceError = ref('');
    const socialSecurityNo = ref('');
    const motError = ref('');
    const motErrorStyle = ref('');
    const minDate = ref('');
    const maxDate = ref('');
    const newDay = ref('');
    const newMonth = ref('');
    // Progress
    const progressBar = ref('');
    // const endMonth = ref('');
    // const endDay = ref('');
    const imageFiles = ref([]);
    const dobMaxYear = ref([]);
    const driverData = ref('');
    const docName = ref(null);
    // Date picker
    const dobDatePicker = ref(null);
    const licenseDatePicker = ref(null);

    const getDate = () => {
      /**
     * Function to get minimum and maximum dates for selection
     */
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      // const date = new Date(year - 1, month, day);

      if (day < 10) {
        newDay.value = `0${day}`;
      } else {
        newDay.value = day;
      }
      if (month < 10) {
        newMonth.value = `0${month}`;
      } else {
        newMonth.value = month;
      }
      minDate.value = `${year - 1}-${newMonth.value}-${newDay.value}`;
      maxDate.value = `${year + 1}-${newMonth.value}-${newDay.value}`;
      dobMaxYear.value = `${year - 19}-${newMonth.value}-${newDay.value}`;
    };
    // End
    const getEndDate = (date, months) => {
    /**
     * Get End date from Start Date
     */
      const newDate = new Date(date);
      const d = newDate.getDate();
      newDate.setMonth(newDate.getMonth() + +months);
      if (newDate.getDate() !== d) {
        newDate.setDate(0);
      }
      return newDate;
    };
    // End

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      // console.log(e.target.name);
      imageFiles.value = [...imageFiles.value,
        { fieldName: e.target.id, fileData: e.target.files },
      ];
      // console.log(imageFiles.value);
    };

    const uploadFile = (imageFile, imageName) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `images/${transporterId.value}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressBar.value = uplodPercent;
      },
      (errors) => { console.log(errors.message); },
      () => { });
      // storageRef.snapshot.ref.getDownloadURL().then((url) => {
      //   // imageURL.value = url;
      //   // console.log(url);
      //   const dumped = ref('');
      //   dumped.value = url;
      // })
      // );
    };

    const getVehicleCategories = async () => {
      try {
        const res = await pentermFirestore.collection('vehicleCategories').where('status', '==', 1).orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((vehicleCat) => (
              { ...vehicleCat.data(), id: vehicleCat.id }
            ));
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    getVehicleCategories();

    // Submit data
    const submitData = async () => {
      const addData = {
        driverSex: driverSex.value,
        driverFullName: driverFullName.value,
        driverDoB: driverDoB.value,
        postCode: postCode.value,
        driverEmail: driverEmail.value,
        addressPostal: addressPostal.value,
        driverPhone: driverPhone.value,
        driverRegion: driverRegion.value,
        driverLicenseType: driverLicenseType.value,
        driverLicenseNumber: driverLicenseNumber.value,
        driverLicenseExpiry: new Date(driverLicenseExpiry.value),
        uid: user.value.uid,
        transporterId: transporterId.value,
        transporterEmail: user.value.email,
        role: 'Driver',
        status: 1,
        jobStatus: 0,
        createdDate: timestamp(),
        updatedDate: timestamp(),
      };
      await pentermFirestore.collection('transporterDrivers').add(addData)
        .then((doc) => {
          // console.log(doc.id);
          const updateVehicleRef = pentermFirestore.collection('transporterDrivers').doc(doc.id);
          /**
           * Bulk upload files
           */
          const images = ref([]);
          imageFiles.value.forEach((data) => {
            const imageName = `${uuidv4()}.jpeg`;
            images.value = [...images.value, { docType: data.fieldName, imageFile: imageName }];
            uploadFile(data.fileData[0], imageName);
            // console.log(images.value);
            const dataFieldName = data.fieldName;
            updateVehicleRef.update({
              [dataFieldName]: imageName,
            });
          });
        });
      router.push({ name: 'Drivers' });
    };
    // submitData();
    if (!fberror.value) {
      // console.log('Added to db');
      // Clear fields after submission

      // Redirect after adding data
      // router.push({ name: 'Transporters' });
    }
    // end

    const uploadImages = () => {
      /**
       * Bulk upload files
       */
      const images = ref([]);
      imageFiles.value.forEach((data) => {
        // console.log(data[0]);
        const imageName = `${uuidv4()}.jpeg`;
        images.value = [...images.value, imageName];
        uploadFile(data.fileData[0], imageName);
        // console.log(images.value);
      });
    };

    // Check if driver assigned already exist
    const getdriverData = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles').where('driverPhone', '==', driverPhone.value)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            driverData.value = snap.docs.map((driver) => (
              { ...driver.data(), id: driver.id }
            ));
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };

    onMounted(() => {
      getDate();
      // License picker
      flatpickr(licenseDatePicker.value, {
        minDate: 'today',
      });
      // License picker
      flatpickr(dobDatePicker.value, {
        maxDate: dobMaxYear.value,
        allowInput: true,
      });
      if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });

    return {
      user,
      getCompanyName,
      docName,
      driverData,
      getdriverData,
      minDate,
      maxDate,
      dobMaxYear,
      getEndDate,
      router,
      submitData,
      dobError, // dob error
      dobErrorStyle, // css
      licenseError,
      licenseErrorStyle, // css
      motError,
      motErrorStyle, // css
      insuranceError,
      socialSecurityNo,
      dateResults,
      driverLicenseType,
      driverLicenseNumber,
      driverLicenseExpiry,
      licenseDatePicker,
      driverRegion,
      dataResults,
      dump,
      error,
      driverSex,
      driverFullName,
      driverDoB,
      driverPhone,
      driverEmail,
      postCode,
      addressPostal,
      fileUpload,
      uploadImages,
      progressBar,
      dobDatePicker,

    };
  },
});
</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.form-stack {
  display: block;
}
.field-margin {
  margin: auto;
  padding: 10px;
}
.page-header-border {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 5px;
}
.page-header {
  margin: 10px auto;
  border-right: 4px solid tomato;
  padding: 10px;
}
.file-input {
  display: none;
}
.hide-imput {
  padding: 1px;
}
.hide-imput input{
  display: none;
}
.date-error {
  border: 1px solid tomato;
}
.required-field{
  color: #cc0000;
}

</style>
