<template>
  <!-- MODALS -->
  <!-- OFFCANVAS -->

  <!-- Offcanvas: Search -->
  <div class="offcanvas offcanvas-start" id="sidebarOffcanvasSearch" tabindex="-1">
    <div class="offcanvas-body" data-list='{"valueNames": ["name"]}'>
      <!-- Form -->
      <form class="mb-4">
        <div class="input-group input-group-merge input-group-rounded input-group-reverse">
          <input class="form-control list-search" type="search" placeholder="Search" />
          <div class="input-group-text">
            <span class="fe fe-search"></span>
          </div>
        </div>
      </form>

      <!-- List group -->
      <div class="my-n3">
        <div class="list-group list-group-flush list-group-focus list">
          <a class="list-group-item" href="./team-overview.html">
            <div class="row align-items-center">
              <div class="col-auto">
              </div>
            </div>
            <!-- / .row -->
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Offcanvas: Activity -->
  <div class="offcanvas offcanvas-start" id="sidebarOffcanvasActivity" tabindex="-1">
    <div class="offcanvas-header">
      <!-- Title -->
      <h4 class="offcanvas-title">
        Notifications
      </h4>

      <!-- Navs -->
      <ul class="nav nav-tabs nav-tabs-sm modal-header-tabs">
        <li class="nav-item">
          <a class="nav-link active" data-bs-toggle="tab" href="#modalActivityAction">Action</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#modalActivityUser">User</a>
        </li>
      </ul>
    </div>
    <div class="offcanvas-body">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="modalActivityAction">
          <!-- List group -->
        </div>
      </div>
    </div>
  </div>

  <!-- NAVIGATION -->
  <nav class="navbar navbar-vertical fixed-start navbar-expand-md navbar-light" id="sidebar">
    <div class="container-fluid">
      <!-- Toggler -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Brand -->
      <a class="navbar-brand" href="#">
        <img src="../assets/penterm_logo_1.png" class="navbar-brand-img mx-auto" alt="Penterm" />
              <!-- Transporter Name -->
      <div>
        {{ getCompanyName }}
      <hr>
      </div>
      </a>

      <!-- User (xs) -->
      <div class="navbar-user d-md-none">
        <!-- Dropdown -->
        <div class="dropdown">
          <!-- Toggle -->
          <a
            href="#"
            id="sidebarIcon"
            class="dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <!-- <div class="avatar avatar-sm avatar-online">
              <img
                src="./assets/img/avatars/profiles/avatar-1.jpg"
                class="avatar-img rounded-circle"
                alt="..."
              />
            </div> -->
          </a>

          <!-- Menu -->
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarIcon">
            <!-- <a href="./profile-posts.html" class="dropdown-item">Profile</a>
            <a href="./account-general.html" class="dropdown-item">Settings</a> -->
            <hr class="dropdown-divider" />
            <a href="#" class="dropdown-item" @click="handleLogout">Logout</a>
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <!-- Form -->
        <form class="mt-4 mb-3 d-md-none">
          <div class="input-group input-group-rounded input-group-merge input-group-reverse">
            <input class="form-control" type="search" placeholder="Search" aria-label="Search" />
            <div class="input-group-text">
              <span class="fe fe-search"></span>
            </div>
          </div>
        </form>

        <!-- Navigation -->
        <ul class="navbar-nav">
          <li class="nav-item">
              <a class="nav-link" href="#sidebarDashboards"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="true" aria-controls="sidebarDashboards">
                <i class="fe fe-home"></i> Dashboards
              </a>
              <div class="collapse show" id="sidebarDashboards">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                     <router-link class="nav-link active" :to="{ name: 'TransporterDashboard'}">
                      Default
                     </router-link>
                  </li>
                </ul>
              </div>
            </li>
            <!-- .end of dashboard nav -->
            <!-- vehicle nav -->
          <li class="nav-item">
              <a class="nav-link" href="#sidebarVehicles" data-bs-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="sidebarVehicles">
                <i class="fe fe-truck"></i> Fleet Management
              </a>
              <div class="collapse " id="sidebarVehicles">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'VehicleOwners'}">
                     Vehicle Owners
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'TransporterVehicles'}">
                     Fleet
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'Drivers'}">
                     Drivers
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
            <!-- .end of vehicle nav -->
          <li class="nav-item">
             <router-link class="nav-link" :to="{ name: 'TransporterJobs'}">
             <i class="fe fe-shopping-cart"></i> Job Board
            </router-link>
          </li>
          <li class="nav-item">
             <router-link class="nav-link" :to="{ name: 'TransporterJobQuotes'}">
             <i class="fe fe-file-text"></i> Job Quotes
            </router-link>
          </li>
          <li class="nav-item">
             <router-link class="nav-link" :to="{ name: 'Expenses'}">
             <i class="fe fe-layers"></i> Expenses
            </router-link>
          </li>
          <li class="nav-item">
             <router-link class="nav-link" :to="{ name: 'Maintenance'}">
             <i class="fe fe-octagon"></i> Maintenance
            </router-link>
          </li>
          <li class="nav-item">
             <router-link class="nav-link" :to="{ name: 'Commission'}">
             <i class="fe fe-octagon"></i> Monthly Commission
            </router-link>
          </li>
          <!-- <li class="nav-item">
             <router-link class="nav-link" :to="{ name: 'Maintenance'}">
             <i class="fe fe-users"></i> Staff
            </router-link>
          </li> -->
          <!--  -->
          <!-- <li class="nav-item">
              <a class="nav-link" href="#sidebarSettings" data-bs-toggle="collapse"
              role="button" aria-expanded="false" aria-controls="sidebarSettings">
                <i class="fe fe-settings"></i> System
              </a>
              <div class="collapse " id="sidebarSettings">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'Maintenance'}">
                     Company Profile
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'Maintenance'}">
                     Departments
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'Maintenance'}">
                     Expense Categories
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'Renewals'}">
                     Renewals
                    </router-link>
                  </li>
                </ul>
              </div>
            </li> -->
          <li class="nav-item">
            <a class="nav-link " href="#" @click="handleLogout">
              <i class="fe fe-log-out"></i> Logout </a>
          </li>
          <!--  -->
          <!-- <li class="nav-item d-md-none">
            <a
              class="nav-link"
              data-bs-toggle="offcanvas"
              href="#sidebarOffcanvasActivity"
              aria-contrtols="sidebarOffcanvasActivity"
            >
              <span class="fe fe-bell"></span> Notifications
            </a>
          </li> -->
        </ul>

        <!-- Divider -->
        <hr class="navbar-divider my-3" />

        <!-- Heading -->
        <!-- <h6 class="navbar-heading">
          Documentation
        </h6> -->
        <!-- Push content down -->
        <div class="mt-auto"></div>

        <!-- User (md) -->
        <div class="navbar-user d-none d-md-flex" id="sidebarUser">
          <!-- Icon Notifications-->
          <a
            class="navbar-user-link"
            data-bs-toggle="offcanvas"
            href="#sidebarOffcanvasActivity"
            aria-controls="sidebarOffcanvasActivity"
          >
            <!-- <span class="icon">
              <i class="fe fe-bell"></i>
            </span> -->
          </a>

          <!-- Dropup -->
          <div class="dropup">
            <!-- Toggle -->
            <a
              href="#"
              id="sidebarIconCopy"
              class="dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <!-- <div class="avatar avatar-sm avatar-online">
                <img
                  src="./assets/img/avatars/profiles/avatar-1.jpg"
                  class="avatar-img rounded-circle"
                  alt="..."
                />
              </div> -->
            </a>

            <!-- Menu -->
            <div class="dropdown-menu" aria-labelledby="sidebarIconCopy">
              <!-- <a href="#" class="dropdown-item">Profile</a>
              <a href="#" class="dropdown-item">Settings</a> -->
              <hr class="dropdown-divider" />
              <a href="#" class="dropdown-item" @click="handleLogout">Logout</a>
            </div>
          </div>

          <!-- Icon  Search -->
          <a
            class="navbar-user-link"
            data-bs-toggle="offcanvas"
            href="#sidebarOffcanvasSearch"
            aria-controls="sidebarOffcanvasSearch"
          >
            <!-- <span class="icon">
              <i class="fe fe-search"></i>
            </span> -->
          </a>
        </div>
      </div>
      <!-- / .navbar-collapse -->
    </div>
  </nav>

  <!-- MAIN CONTENT -->
  <div class="main-content">
    <router-view />
  </div>
  <!-- / .main-content -->
  <!-- new ui -->
  <!--  -->
  <!-- MODALS -->

</template>
<script>
import { ref } from '@vue/runtime-dom';
import { useRouter } from 'vue-router';
import firebase from 'firebase';
// import NavCompany from './NavCompany.vue';
import getUser from '../composables/getUser';
import useLogout from '../composables/useLogout';

export default {
  name: 'Admin',
  props: [],
  components: {
    // NavCompany,
  },
  setup() {
    const { user, userDetails, getCompanyName } = getUser();
    const { error, logout } = useLogout();
    const router = useRouter();
    const loggedInUser = ref('');
    const connectionStatus = ref('');

    const getConnectionState = () => {
      const connectedRef = firebase.database().ref('.info/connected');
      connectedRef.on('value', (snap) => {
        if (snap.val() === true) {
          // console.log('connected');
        } else {
          // console.log('not connected');
          connectionStatus.value = 'No Internet connection';
        }
      });
    };

    const handleLogout = async () => {
      await logout();
      if (!error.value) {
        // console.log('User logged out');
        // Redirect on logout
        router.push({ name: 'Login' });
      }
    };
    getConnectionState();

    // console.log('status', transporterStatus.value);

    return {
      user,
      handleLogout,
      loggedInUser,
      userDetails,
      getCompanyName,
    };
  },
};
</script>
<style>

</style>
