<template>
<!--  -->

<div class="card">
  <div class="card-body">
<!--  -->
  <h4 class="display-6 text-start" style="font-size: 130%">Editing Details<hr></h4>
  <!-- <button class="btn btn-primary" @click="uploadImages">Click me</button> -->

      <form @submit.prevent="submitData" autocomplete="off" class="py-2">
        <h4 class="text-primary text-start text-danger">Owner Details</h4>
          <div class="row">
            <!--  -->
            <div class="col-xs-12 col-md-2">
              <div class="form-floating mb-2">
                <select class="form-control" required v-model="ownerSex">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
                <label>Sex <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-4">
              <div class="form-floating mb-4">
                <input type="text" class="form-control"
                required v-model="ownerFullName">
                <label>Owner full name <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="date" class="form-control"
                required v-model="ownerDoB">
                <label>Date Of Birth <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="tel" class="form-control"
                required v-model="ownerPhone">
                <label>Phone Number <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <!-- end of row -->
          </div>
          <div class="row">
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="email" class="form-control" disabled
                v-model="ownerEmail" required>
                <label>Email <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
  <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="addressGPS">
                <label>GhanaPost Address <span class="required-field"> *</span></label>
              </div>
            </div>
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                v-model="addressPostal">
                <label>Post Box [optional]</label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" required v-model="ownerRegion">
                  <option value="">Select</option>
                  <option value="Oti Region">Oti Region</option>
                  <option value="Bono East Region">Bono East Region</option>
                  <option value="Ahafo Region">Ahafo Region</option>
                  <option value="Bono Region">Bono Region</option>
                  <option value="North East  Region">North East Region</option>
                  <option value="Savannah Region">Savannah Region</option>
                  <option value="Western North Region">Western North Region</option>
                  <option value="Western Region">Western Region</option>
                  <option value="Volta Region">Volta Region</option>
                  <option value="Greater Accra Region ">Greater Accra Region </option>
                  <option value="Eastern Region">Eastern Region</option>
                  <option value="Ashanti Region">Ashanti Region</option>
                  <option value="Northern Region">Northern Region </option>
                  <option value="Central Region">Central Region</option>
                  <option value="Upper East Region">Upper East Region</option>
                  <option value="Upper West Region">Upper West Region </option>
                </select>
                <label>Region <span class="required-field"> *</span></label>
              </div>
            </div>
            <!-- End of Row -->
          </div>
          <!--  -->
      <div class="row">
        <!-- Vehicle Types -->
        <h4 class="text-primary text-start text-danger">Next Of Kin Details:</h4>
        <div class="row py-2">
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="nokFullName">
                <label>Full Name <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="nokPhone">
                <label>Phone Number <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="nokEmail">
                <label>Email <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="date" class="form-control"
                required v-model="nokDoB" :max="dobMaxYear">
                <label>Date Of Birth <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            </div>
      </div>
      <h4 class="text-primary text-start text-danger">
        Required Documents [ All Must images accepted formats *.png,*.jpg,*jpeg ]
        </h4>
        <div class="row py-2">
          <div class="col-xs-4 col-md-6">
            <h4 class="">
              Owner Identification
              <span class="required-field"> *</span>
              </h4>
              <label class="py-2">
                <input type="file" required class="form-control"
                id="ownerID" name="Owner Identification"
                capture="user" accept="image/*" @change="fileUpload">
            </label>
          </div>
          <div class="col-xs-4 col-md-6">
            <h4 class="">
              Next Of Kin Identification
              </h4>
              <label class="py-2">
                <input type="file" class="form-control"
                id="nokID" name="Next Of Kin"
                capture="user" accept="image/*" @change="fileUpload">
            </label>
          </div>
          <div class="col-xs-4 col-md-3">
            &nbsp;
          </div>
          <div class="col-xs-4 col-md-3">
            &nbsp;
          </div>
          <!--  -->
          </div>
        <!-- Submit -->
                <div class="row">
            <div class="col-xs-12 col-md-12"><span class="error">{{ error }}</span></div>
            <div class="col-xs-12 col-md-12 text-center">
              <button class="btn btn-danger">Submit</button>
            </div>
          </div>
      <!-- form end -->
        </form>
    </div>
</div>

</template>

<script>
import {
  ref, onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
// import {
//   getStorage, useStorageEmulator, ref as imgRef, uploadBytes,
// } from 'firebase/storage';
import getUser from '../../composables/getUser';
import { timestamp, pentermFirestore } from '../../firebase/config';

export default ({
  components: {
  },
  props: {
    id: String,
  },
  setup(props) {
    const {
      user, userDetails, getUserRole,
    } = getUser();
    const router = useRouter();
    const fberror = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const error = ref('');
    const publicPath = process.env.BASE_URL;
    const makeYear = ref([]);
    const ownerSex = ref('');
    const ownerFullName = ref('');
    const ownerDoB = ref('');
    const ownerPhone = ref('');
    const ownerEmail = ref('');
    const addressGPS = ref('');
    const addressPostal = ref('');
    const ownerRegion = ref('');
    const nokFullName = ref('');
    const nokPhone = ref('');
    const nokDoB = ref('');
    const nokEmail = ref('');
    const dateResults = ref('');
    const minDate = ref('');
    const maxDate = ref('');
    const newDay = ref('');
    const newMonth = ref('');
    const endMonth = ref('');
    const endDay = ref('');
    const imageFiles = ref([]);
    const dobMaxYear = ref([]);
    const ownerDetails = ref('');

    const getDate = () => {
      /**
     * Function to get minimum and maximum dates for selection
     */
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      // const date = new Date(year - 1, month, day);

      if (day < 10) {
        newDay.value = `0${day}`;
      } else {
        newDay.value = day;
      }
      if (month < 10) {
        newMonth.value = `0${month}`;
      } else {
        newMonth.value = month;
      }
      minDate.value = `${year - 1}-${newMonth.value}-${newDay.value}`;
      maxDate.value = `${year + 1}-${newMonth.value}-${newDay.value}`;
      dobMaxYear.value = `${year - 1}-${newMonth.value}-${newDay.value}`;
    };
    // End
    const getEndDate = (date) => {
    /**
     * Get End date from Start Date
     */
      const year = new Date(date).getFullYear() + 1;
      const month = new Date(date).getMonth();
      const day = new Date(date).getDate();
      if (month === 0) {
        endMonth.value = 12;
      } else if (month < 10) {
        endMonth.value = `0${month}`;
      } else {
        endMonth.value = month;
      }
      if (day === 0 && endMonth.value === 12) {
        endDay.value = '31';
      } else if (day < 10) {
        endDay.value = `0${day}`;
      } else {
        endDay.value = day;
      }
      const endDate = `${year}-${endMonth.value}-${endDay.value}`;

      return new Date(endDate);
    };
    // End

    // Get Owner
    const getOwners = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicleOwners').doc(props.id).get();
        // console.log('waiting for data');
        if (res) {
          ownerDetails.value = res.data();
          ownerSex.value = res.data().ownerSex;
          ownerFullName.value = res.data().ownerFullName;
          ownerDoB.value = res.data().ownerDoB;
          addressGPS.value = res.data().addressGPS;
          ownerEmail.value = res.data().ownerEmail;
          addressPostal.value = res.data().addressPostal;
          ownerPhone.value = res.data().ownerPhone;
          ownerRegion.value = res.data().ownerRegion;
          nokFullName.value = res.data().nokFullName;
          nokEmail.value = res.data().nokEmail;
          nokPhone.value = res.data().nokPhone;
          nokDoB.value = res.data().nokDoB;
        }
      } catch (err) {
        error.value = err.message;
      }
    };

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      // console.log(e.target.files);
      imageFiles.value = [...imageFiles.value, e.target.files];
      console.log(imageFiles.value);
    };

    const uploadFile = (imageFile, imageName) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `images/${userDetails.value.transporterId}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(uplodPercent);
      },
      (errors) => { console.log(errors.message); },
      () => { },
      storageRef.snapshot.ref.getDownloadURL().then((url) => {
        // imageURL.value = url;
        // console.log(url);
        const dumped = ref('');
        dumped.value = url;
      }));
    };

    // Get the list of make year from current year
    const currentDate = new Date();
    const currentDateYear = currentDate.getFullYear() + 1;
    for (let year = (currentDateYear - 12); year < currentDateYear; year += 1) {
      makeYear.value.push(year);
    }

    // Submit data
    const submitData = async () => {
      /**
           * Bulk upload files
           */
      const images = ref([]);
      imageFiles.value.forEach((data) => {
        const imageName = `${uuidv4()}.jpeg`;
        images.value = [...images.value, imageName];
        uploadFile(data[0], imageName);
        // console.log(images.value);
      });
      const updateVehicleRef = pentermFirestore.collection('transporterVehicleOwners').doc(props.id);
      await updateVehicleRef.update({
        ownerSex: ownerSex.value,
        ownerFullName: ownerFullName.value,
        ownerDoB: ownerDoB.value,
        addressGPS: addressGPS.value,
        ownerEmail: ownerEmail.value,
        addressPostal: addressPostal.value,
        ownerPhone: ownerPhone.value,
        ownerRegion: ownerRegion.value,
        nokFullName: nokFullName.value,
        nokEmail: nokEmail.value,
        nokPhone: nokPhone.value,
        nokDoB: nokDoB.value,
        updatedDate: timestamp(),
        updatedBy: user.value.displayName,
        documents: images.value,
      });
      /** End */
      router.go({ name: 'VehicleOwnerDetail', params: { id: props.id, pop: 'opp' } });
    };
    // submitData();
    if (!fberror.value) {
      // console.log('Added to db');
      // Clear fields after submission

      // Redirect after adding data
      // router.push({ name: 'Transporters' });
    }
    // end

    const uploadImages = () => {
      /**
       * Bulk upload files
       */
      const images = ref([]);
      imageFiles.value.forEach((data) => {
        // console.log(data[0]);
        const imageName = `${uuidv4()}.jpeg`;
        images.value = [...images.value, imageName];
        uploadFile(data[0], imageName);
        console.log(images.value);
      });
    };

    onMounted(() => {
      getDate();
      getOwners();
      // getEndDate();
      if (getUserRole.value === 'owner') {
        router.push({ name: 'TransporterOwnerJobs' });
      }
    });

    return {
      user,
      minDate,
      maxDate,
      ownerDetails,
      dobMaxYear,
      getEndDate,
      router,
      submitData,
      dateResults,
      ownerRegion,
      dataResults,
      dump,
      error,
      publicPath,
      ownerSex,
      ownerFullName,
      ownerDoB,
      ownerPhone,
      ownerEmail,
      addressGPS,
      addressPostal,
      nokFullName,
      nokPhone,
      nokEmail,
      nokDoB,
      fileUpload,
      uploadImages,

    };
  },
});
</script>

<style lang="css" scoped>

</style>
