<template>
<div class="loader-center" v-if="!dataResults">
<div class="lds-ripple"><div></div><div></div></div>
</div>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Job Report</h6>

            <!-- Title -->
            <div>
              <h1 class="header-title">
              Vehicle Report
              </h1>
            </div>
            <!-- <h1 class="header-title">Job Report for {{vehicleId}} - {{ dateRange }}</h1> -->
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <router-link class="btn btn-primary lift"
            :to="{ name: 'TransporterNewJob'}">
              Create Job
            </router-link> -->
          </div>
<div class="col-auto">
                <!-- Button -->
                <!-- <a href="#!" class="btn btn-sm btn-white"> Add </a> -->
                <input type="text" class="form-control" ref="datePicker" v-model="dateRange"
                placeholder="Date Range" data-flatpickr='{"mode": "range"}'>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <!-- <a href="#!" class="btn btn-sm btn-white"> Add </a> -->
                <!-- Vehicle List -->
                  <select class="form-control" v-model="vehicleId">
                    <option v-for="vehicle in vehicleList"
                    :value="vehicle.vehicleReg"
                    :key="vehicle.id">
                      {{ vehicle.vehicleReg}}
                    </option>
                  </select>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <a href="#!" class="btn btn-sm btn-white"
                @click="filterJobs(); filterMaintenance(); filterExpenses()">
                Filter </a>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <a href="#!" class="btn btn-sm btn-white" @click="resetFilter">Clear Filter </a>
              </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->
<div class="container-fluid" v-if="dataResults">
  <div class="row">

<!-- vehicle details -->
  <div class="row" v-if="startDate">
    <div class="col-12 col-lg-6 col-xl">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <div class="row align-items-end">
                    <div class="col">
                      <!-- Title -->
                      <div>
                        <h1 class="header-title" v-if="startDate">
                        Vehicle: {{ selectedVehicle }}
                        </h1>
                      </div>
                      <div v-if="startDate">
                        <span class="text-muted">Reporting Period</span>
                        <h3 class="header-title" >
                          {{ startDate.toDate().toDateString() }}
                          to {{ endDate.toDate().toDateString() }}
                        </h3>
                      </div>

                    </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
  </div>
<!-- End -->
    </div>
  <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Income (Payout)</h6>

                <!-- Heading -->
                <span class="h2 mb-0">
                 GH&cent; {{ revenue - monthlyMaintenanceTotal - monthlyExpenseTotal }}
                </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-primary mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Hours -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Revenue</h6>

                <!-- Heading -->
                <span class="h2 mb-0">
                 GH&cent; {{ revenue }}
                </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shopping-cart text-primary mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Expenses</h6>

                <!-- Heading -->
                <span class="h2 mb-0">GH&cent; {{ monthlyExpenseTotal }} </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-trash text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Maintenance</h6>

                <!-- Heading -->
                <span class="h2 mb-0">GH&cent; {{ monthlyMaintenanceTotal }} </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-trash text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- Job list below -->
  <div class="row">
      <div class="col-4">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Jobs</h4>
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div
            class="table-responsive mb-0"
          data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <!-- <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Status
                    </a>
                  </th> -->
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                    Date
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-date"
                    >
                      Revenue
                    </a>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="job in dataResults" :key="job.id">
                  <!-- <td class="goal-status">
                    <div v-if="job.status === 0">
                      <span class="text-primary">●</span> Booked
                    </div>
                    <div v-if="job.status === 1">
                      <span class="text-warning">●</span> In Progress
                    </div>
                    <div v-if="job.status === 2">
                      <span class="text-success">●</span> Completed
                    </div>
                  </td> -->
                  <td class="text-start">
                    <time datetime="2018-10-24">{{ job.pickupDate.toDate().toDateString() }}</time>
                  </td>
                  <td class="text-start">
                    GH&cent; {{ job.finalRevenue }}
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterJobDetail', params: { id: job.id } }">
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-4">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Maintenance</h4>
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div
            class="table-responsive mb-0"
          data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Date
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Type
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-date"
                    >
                      Amount
                    </a>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="mData in maintenanceDataResults" :key="mData.id">
                  <td class="goal-status">
                   <time datetime="2018-10-24">
                      {{ mData.maintenanceDate.toDate().toDateString() }}
                      </time>
                  </td>
                  <td class="text-start">
                    {{ mData.maintenanceType }}
                  </td>
                  <td class="text-start">
                    <time datetime="2018-10-24">
                      GH&cent; {{ mData.amount }}
                      </time>
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterJobDetail', params: { id: mData.id } }">
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- expenses -->
      <div class="col-4">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Expenses</h4>
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div
            class="table-responsive mb-0"
          data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Date
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Type
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-date"
                    >
                      Amount
                    </a>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="eData in expenseDataResults" :key="eData.id">
                  <td class="goal-status">
                   <time datetime="2018-10-24">
                      {{ eData.expenseDate.toDate().toDateString() }}
                      </time>
                  </td>
                  <td class="text-start">
                    {{ eData.expenseType }}
                  </td>
                  <td class="text-start">
                    <time datetime="2018-10-24">
                      GH&cent; {{ eData.ammount }}
                      </time>
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <!-- <div class="dropdown-menu dropdown-menu-end">
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterJobDetail', params: { id: eData.id } }">
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </router-link>
                      </div> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- end of expenses -->
    </div>
</div>
</template>
<script>
import { ref, onMounted } from '@vue/runtime-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import flatpickr from 'flatpickr';
// import { useRouter } from 'vue-router';
// import * as firebase from 'firebase/app';
// import Timestamp from 'firebase/firestore';
import getUser from '../../../composables/getUser';
import { pentermFirestore } from '../../../firebase/config';

export default {
  name: 'Jobs',
  props: [''],
  setup() {
    // const { user, userDetails, getUserRole } = getUser();
    const { user, userDetails } = getUser();
    // const router = useRouter();
    const loggedInUser = ref('');
    const error = ref('');
    const dataResults = ref([]);
    const maintenanceDataResults = ref([]);
    const expenseDataResults = ref([]);
    const dump = ref('');
    // let last;
    const records = ref('');
    const disableBtn = ref('');
    const fetchData = ref(25);
    const datePicker = ref(null);
    const dateRange = ref('');
    const vehicleId = ref('');
    const totalRevenue = ref(0);
    const revenue = ref(0);
    const cost = ref(0);
    const fuel = ref(0);
    const expenses = ref(0);
    const maintenance = ref(0);
    const monthlyMaintenanceTotal = ref(0);
    const monthlyExpenseTotal = ref(0);
    const vehicleList = ref('');
    const selectedVehicle = ref('');
    const startDate = ref('');
    const endDate = ref('');

    // end
    const getDateRange = () => {
      console.log(dateRange.value);
      const dateRangeValue = dateRange.value.split(' ');
      console.log('start date', dateRangeValue[0]);
      console.log('end date', dateRangeValue[2]);
    };
    // Totals
    const getTotals = () => {
      // Reset values on load
      cost.value = 0;
      revenue.value = 0;
      fuel.value = 0;
      expenses.value = 0;
      maintenance.value = 0;
      // End of reset
      dataResults.value.forEach((job) => {
        // console.log(job.finalRevenue);
        // Cost
        cost.value += Number(job.cost);
        // console.log('Total Cost', cost.value);
        // Revenue
        revenue.value += Number(job.finalRevenue);
        // console.log('Total Revenue', revenue.value);
        // Fuel
        fuel.value += Number(job.finalFuel);
        // console.log('Total Fuel', fuel.value);
        // Expenses
        expenses.value += Number(job.jobExpenses);
        // console.log('Total Expenses', expenses.value);
        // Maintenance
        maintenance.value += Number(job.jobMaintenace);
        // console.log('Total Maintenance', maintenance.value);
      });
    };

    // Totals
    const getMaintenanceTotals = () => {
      monthlyMaintenanceTotal.value = 0;
      // End of reset
      maintenanceDataResults.value.forEach((maintenanceData) => {
        // console.log(job.finalRevenue);
        // Cost
        monthlyMaintenanceTotal.value += Number(maintenanceData.amount);
        // console.log('Total Maintenance', monthlyMaintenanceTotal.value);
      });
    };

    // Totals
    const getExpenseTotals = () => {
      monthlyExpenseTotal.value = 0;
      // End of reset
      expenseDataResults.value.forEach((expenseData) => {
        // console.log(job.finalRevenue);
        // Cost
        monthlyExpenseTotal.value += Number(expenseData.ammount);
        // console.log('Total Expense', monthlyExpenseTotal.value);
      });
    };

    const vehicles = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            vehicleList.value = snap.docs.map((vehicle) => ({ ...vehicle.data(), id: vehicle.id }));
          });
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };

    const filterJobs = async () => {
      const dateRangeValue = dateRange.value.split(' ');
      startDate.value = firebase.firestore.Timestamp.fromDate(new Date(dateRangeValue[0]));
      endDate.value = firebase.firestore.Timestamp.fromDate(new Date(dateRangeValue[2]));
      selectedVehicle.value = vehicleId.value;
      try {
        const res = await pentermFirestore.collection('transporterJobs').where('transporterId', '==', userDetails.value.transporterId)
          .where('truckRegistration', '==', vehicleId.value)
          .where('pickupDate', '>=', startDate.value)
          .where('pickupDate', '<=', endDate.value)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            getTotals();
          });
        dump.value = res;
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };

    const filterMaintenance = async () => {
      const dateRangeValue = dateRange.value.split(' ');
      startDate.value = firebase.firestore.Timestamp.fromDate(new Date(dateRangeValue[0]));
      endDate.value = firebase.firestore.Timestamp.fromDate(new Date(dateRangeValue[2]));
      selectedVehicle.value = vehicleId.value;
      try {
        const res = await pentermFirestore.collection('transporterMaintenance').where('transporterId', '==', userDetails.value.transporterId)
          .where('vehicleReg', '==', vehicleId.value)
          // .orderBy('maintenanceDate')
          // .startAt(startDate.value)
          // .endAt(endDate.value)
          .where('maintenanceDate', '>=', startDate.value)
          .where('maintenanceDate', '<=', endDate.value)
          .onSnapshot((snap) => {
            console.log(snap.docs);
            maintenanceDataResults.value = snap.docs.map((maintenanceData) => (
              { ...maintenanceData.data(), id: maintenanceData.id }));
            // console.log(maintenanceDataResults.value);
            // console.log(vehicleId.value);
            // console.log(startDate.value);
            // console.log(endDate.value);
            getMaintenanceTotals();
          });
        dump.value = res;
      } catch (err) {
        console.log('Error >> !');
        console.log(err.message);
        error.value = err.message;
      }
    };

    const filterExpenses = async () => {
      const dateRangeValue = dateRange.value.split(' ');
      startDate.value = firebase.firestore.Timestamp.fromDate(new Date(dateRangeValue[0]));
      endDate.value = firebase.firestore.Timestamp.fromDate(new Date(dateRangeValue[2]));
      selectedVehicle.value = vehicleId.value;
      try {
        const res = await pentermFirestore.collection('transporterExpenses').where('transporterId', '==', userDetails.value.transporterId)
          .where('vehicleReg', '==', vehicleId.value)
          // .orderBy('maintenanceDate')
          // .startAt(startDate.value)
          // .endAt(endDate.value)
          .where('expenseDate', '>=', startDate.value)
          .where('expenseDate', '<=', endDate.value)
          .onSnapshot((snap) => {
            console.log(snap.docs);
            expenseDataResults.value = snap.docs.map((expenseData) => (
              { ...expenseData.data(), id: expenseData.id }));
            // console.log(maintenanceDataResults.value);
            // console.log(vehicleId.value);
            // console.log(startDate.value);
            // console.log(endDate.value);
            getExpenseTotals();
          });
        dump.value = res;
      } catch (err) {
        console.log('Error >> !');
        console.log(err.message);
        error.value = err.message;
      }
    };

    const resetFilter = () => {
      cost.value = 0;
      revenue.value = 0;
      fuel.value = 0;
      expenses.value = 0;
      maintenance.value = 0;
      dataResults.value = [];
      monthlyMaintenanceTotal.value = 0;
      monthlyExpenseTotal.value = 0;
      // vehicleList.value = '';
      dateRange.value = '';
    };
    const jobs = async () => {
      // const start = new Date('2021-08-01').getTime() / 1000;
      // const start = Date.now();
      // const end = new Date('2021-08-31').getTime() / 1000;
      // .where('createdDate', '<=', '1630723231251')
      try {
        const res = await pentermFirestore.collection('transporterJobs').where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc').limit(fetchData.value)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            // last = snap.docs[snap.docs.length - 1];
            records.value = snap.docs.length;
            // console.log(last.data());
            disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
        // dataResult.value = res.docs.map((trip)=>{
        //   return { ...trip.data(), id: trip.id }
        // })
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    vehicles();

    onMounted(() => {
      // jobs();
      vehicles();
      flatpickr(datePicker.value, { mode: 'range' });
      // if (getUserRole.value === 'admin') {
      //   jobs();
      // } else if (getUserRole.value === 'driver') {
      //   router.push({ name: 'TransporterDriverJobs' });
      // }
    });
    return {
      user,
      loggedInUser,
      dataResults,
      jobs,
      records,
      disableBtn,
      datePicker,
      vehicleId,
      dateRange,
      getDateRange,
      filterJobs,
      filterMaintenance,
      filterExpenses,
      totalRevenue,
      startDate,
      endDate,
      revenue,
      cost,
      fuel,
      selectedVehicle,
      expenses,
      maintenance,
      vehicleList,
      resetFilter,
      maintenanceDataResults,
      monthlyMaintenanceTotal,
      expenseDataResults,
      monthlyExpenseTotal,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.jobs {
  background: white;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
  display: block;
  padding: 2px;
}
</style>
