<template>
<!--  -->
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Vehicle</h6>

            <!-- Title -->
            <h1 class="header-title">
               {{ dataResult.truckId }} :-: {{dataResult.vehicleMakeYear}}
              {{dataResult.vehicleMake}}, {{dataResult.categoryName}}
            </h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->
<div class="loader-center" v-if="!dataResult">
<div class="lds-ripple"><div></div><div></div></div>
</div>
  <!-- Data -->
  <div class="container-fluid" v-if="dataResult">
        <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
    <div class="text-start">
      <h3>Driver: {{dataResult.driverFullName}}</h3>
    </div>
    <div class="text-start text-mute">
      License No: {{dataResult.driverLicenseNumber}},
      valid until {{ dataResult.driverLicenseExpiry.toDate().toDateString() }}.
    </div>
    <div class="job-date text-start"><i class="fas fa-phone-alt"></i> {{ dataResult.driverPhone }},
    &nbsp;<i class="fas fa-envelope"></i> {{ dataResult.driverEmail }}.
    </div>
    <div class="job-date text-start"><i class="fas fa-home"></i> {{ dataResult.addressGPS }},
    &nbsp;<i class="fas fa-envelope"></i> {{ dataResult.addressPostal }}.
    </div>
    <table class="table table-hover text-start">
  <thead>
        <tr>
          <th scope="col" colspan="4">
            {{ dataResult.vehicleVIN }}
          </th>
        </tr>
  </thead>
  <tbody>
    <tr>
      <td>Insurance Company: {{ dataResult.insuranceCompany }}</td>
      <td>Type: {{ dataResult.insuranceType }}</td>
      <td>Start: {{ dataResult.insuranceStart.toDate().toDateString() }}</td>
      <td>Until: {{ dataResult.insuranceEnd.toDate().toDateString() }}</td>
    </tr>
    <tr>
      <td>Road Worthy/MOT</td>
      <td>&nbsp;</td>
      <td>Start: {{ dataResult.motStart.toDate().toDateString() }}</td>
      <td>Until: {{ dataResult.motEnd.toDate().toDateString() }}</td>
    </tr>
    <tr>
      <td colspan="4">
        Required Documents
      </td>
    </tr>
    <tr>
      <td v-if="dataResult.documents" colspan="4">
        <div class="row py-2" >
          <div class="col-xs-3 col-md-2 py-2"
          v-for="document in dataResult.documents" :key="document">
            <!-- <img :src="getImageURL(`images/${transporterId}/${document}`)"
            class="img-thumbnail py-2"> -->
            <button type="button"
            @click="getImageURL(`images/${transporterId}/${document.imageFile}`)"
            class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#imagePreviewModal">
              {{document.docType}}
            </button>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="4">
            <!-- Modal -->
            <div class="modal fade"
            id="imagePreviewModal" tabindex="-1"
            aria-labelledby="imagePreviewModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="imagePreviewModalLabel">Document Preview</h5>
                    <button type="button"
                    class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                     <img :src="imageURL"
            class="img-thumbnail py-2">
                  </div>
                  <div class="modal-footer">
                    <button type="button"
                    class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <a :href="imageURL" download target="_blank">
                    <button type="button" class="btn btn-primary">Download</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
      </td>
    </tr>
  </tbody>
    </table>
          </div>
        </div>
      </div>
  </div>
</div>

</template>
<script>
import { ref } from '@vue/runtime-dom';
import firebase from 'firebase';
import { useRoute } from 'vue-router';
import getUser from '../../composables/getUser';
import { pentermFirestore } from '../../firebase/config';

export default {
  name: 'JobDetail',
  components: { },
  setup() {
    const { transporterId } = getUser();
    const error = ref('');
    const dataResult = ref('');
    const route = useRoute();
    // const router = useRouter();
    const pickupLat = ref('');
    const pickupLng = ref('');
    const imageURL = ref('');

    const job = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles').doc(route.params.id).get();
        // console.log('waiting for data');
        // console.log(res.data());
        if (!res.exists) {
          throw Error('That trip does not exists');
        }
        dataResult.value = res.data();
        pickupLat.value = dataResult.value.pickupCoordinates.latitude;
        pickupLng.value = dataResult.value.pickupCoordinates.longitude;
        //  Collect fields for update
        // source.value = res.data().source;
        // destination.value = res.data().destination;
        // price.value = res.data().price;
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    job();
    const getImageURL = (imagePath) => {
      const storageRef = firebase.storage().ref();
      storageRef.child(imagePath).getDownloadURL()
        .then((url) => { imageURL.value = url; });
      console.log(imageURL.value);
    };
    // end
    return {
      dataResult, transporterId, getImageURL, imageURL,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px ;
  display: block;
}
.job {
 margin: auto;
}
.menu-items{
  display: block;
}
.trips{
    width: 98%;
    max-width: 960px;
    margin: 10px auto;
    border-radius: 4px;
    box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
    background: white;
    padding: 10px;

}
.trips:hover{
  background: #F7F9FB;
  cursor: pointer;
}
.jobdetails{
  display: table-row;
  border: 1px solid #bbb;
}
.pad-body {
  padding: 10px;
  margin: 10px;
}
</style>
