<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Driver Assigment</h6>

            <!-- Title -->
            <h1 class="header-title">{{driverDetail.driverFullName}}</h1>
          </div>
          <!--  -->
          <div class="col-auto">
            <!-- Button -->
            <router-link replace
              class="btn btn-secondary lift"
              :to="{ name: 'Drivers' }"
            >
            Drivers
            </router-link>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid" v-if="dataResults">
    <!-- Job list below -->
    <div class="row">
      <div class="col-12">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Title -->
                <h4 class="card-header-title">Fleet</h4>
              </div>
              <div class="col-auto">
                <!-- Button -->
                <!-- <a href="#!" class="btn btn-sm btn-white"> Add </a> -->
              </div>
            </div>
            <!-- / .row -->
          </div>
          <div class="table-responsive mb-0">
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a href="#" class="text-muted"> Owner </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> License Plate </a>
                  </th>
                  <th>
                    <a href="#" class="text-muted"> Driver </a>
                  </th>
                  <th>Assingment</th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="vehicle in dataResults" :key="vehicle.id">
                  <td class="goal-project">{{ vehicle.ownerFullName }}</td>
                  <td class="goal-status">
                    {{ vehicle.vehicleReg }}
                  </td>
                  <td class="goal-progress">
                    {{ vehicle.driverFullName }}
                  </td>
                  <td class="text-end">
                    <button class="btn btn-primary btn-sm" v-if="!vehicle.driverFullName"
                    @click="updateDriver(driverDetail,vehicle.id, vehicle.vehicleReg )">
                    Assign
                    </button>
                    <button class="btn btn-primary btn-sm" v-if="vehicle.driverFullName"
                    @click="removeDriver(driverDetail,vehicle.id )">
                    Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import firebase from 'firebase';
import { ref, onMounted } from '@vue/runtime-dom';
import { useRoute, useRouter } from 'vue-router';
import getUser from '../../composables/getUser';
import { timestamp, pentermFirestore } from '../../firebase/config';

export default {
  name: '',
  props: [''],
  setup() {
    const { user, userDetails } = getUser();
    // const router = useRouter();
    const loggedInUser = ref('');
    const error = ref('');
    const dataResults = ref([]);
    const dump = ref('');
    // let last;
    const records = ref('');
    const disableBtn = ref('');
    const fetchData = 25;
    // const fileName = ref('');
    const today = ref('');
    const router = useRouter();
    const route = useRoute();
    const driverDetail = ref('');

    today.value = new Date();
    // end
    const vehicles = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterVehicles')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .limit(fetchData)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((vehicle) => ({
              ...vehicle.data(),
              id: vehicle.id,
            }));
            // last = snap.docs[snap.docs.length - 1];
            records.value = snap.docs.length;
            // console.log(last.data());
            disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    const driver = async () => {
      try {
        const res = await pentermFirestore.collection('transporterDrivers').doc(route.params.driver).get();
        // console.log('waiting for data');
        if (res) {
          driverDetail.value = res.data();
        }
      } catch (err) {
        error.value = err.message;
      }
    };

    const updateDriver = async (driverInfo, vehicleID, vehicleNo) => {
      const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(vehicleID);
      await vehicleRef.update({
        driverFullName: driverInfo.driverFullName,
        driverUid: route.params.driver,
        updatedDate: timestamp(),
        updatedBy: user.value.displayName,
      });
      const driverRef = pentermFirestore.collection('transporterDrivers').doc(route.params.driver);
      await driverRef.update({
        vehicleReg: vehicleNo,
        vehicleUid: vehicleID,
        updatedDate: timestamp(),
        updatedBy: user.value.displayName,
      });
      router.push({ name: 'Drivers' });
    };
    const removeDriver = async (driverInfo, vehicleID) => {
      const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(vehicleID);
      await vehicleRef.update({
        driverFullName: '',
        driverUid: '',
        updatedDate: timestamp(),
        updatedBy: user.value.displayName,
      });
      const driverRef = pentermFirestore.collection('transporterDrivers').doc(route.params.driver);
      await driverRef.update({
        vehicleReg: '',
        vehicleUid: '',
        updatedDate: timestamp(),
        updatedBy: user.value.displayName,
      });
      // router.push({ name: 'Drivers' });
    };
    vehicles();
    driver();

    onMounted(() => {
      vehicles();
      driver();
      // if (userDetails.value.accessType === 'driver') {
      //   router.push({ name: 'TransporterDriverJobs' });
      // }
    });

    return {
      user,
      today,
      loggedInUser,
      dataResults,
      driverDetail,
      records,
      disableBtn,
      updateDriver,
      removeDriver,
    };
  },
};
</script>
<style>
</style>
