<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Maintenance</h6>

            <!-- Title -->
            <h1 class="header-title">Dashboard</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
      <div class="row">
      <div class="col-12 col-lg-4">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <!-- Form -->
            <form @submit.prevent="submitData">
              <div class="row g-3">
                <!-- <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Search Vehicle</label>
                  <input type="text" class="form-control"
                  autocomplete="off" />
                </div> -->
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Vehicle</label>
                  <select class="form-select mb-3" v-model="vehicle"
                  autocomplete="off" required>
                    <option v-for="vehicle in dataResults"
                    :value="vehicle.vehicleReg" :key="vehicle.id">
                      {{vehicle.vehicleReg}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row g-3">
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Maintenance Type</label>
                  <select class="form-select mb-3"
                  autocomplete="off" v-model="maintenance" required>
                    <option value="oil">Oil Change</option>
                    <option value="tires">Tires</option>
                    <option value="brakes">Brakes</option>
                    <option value="engine">Engine</option>
                    <option value="others">Others</option>
                  </select>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Date</label>
                  <input type="text" class="form-control" v-model="maintenanceDate"
                  autocomplete="off" required ref="expenseDatePicker" />
                </div>
              </div>
              <div class="row g-3">
                <div class="col-12 col-md-8 mb-3">
                  <label class="form-label">Paid To</label>
                  <input type="text" class="form-control" autocomplete="off"
                  v-model="paymentTo" required />
                </div>
                <div class="col-12 col-md-4 mb-3">
                  <label class="form-label">Ammount</label>
                  <input type="number" class="form-control" step="0.1" v-model="amount"
                  placeholder="1000.50" autocomplete="off" required />
                </div>
              </div>
              <div class="row g-3">
                <div class="col-12 col-md-12 mb-3">
                <label class="text-danger">Files *</label>
                  <input type="file" class="form-control"
                  id="maintenanceRecieptPhoto" name="Fuel Receipt"
                  capture="user" @change="fileUpload" required>

                </div>
              </div>
              <div class="row g-3">
                <div class="col-12 col-md-12 mb-3">
                  <label class="form-label">Comments</label>
                  <textarea class="form-control" cols="30" rows="5"
                  required v-model="comments"></textarea>
                </div>
              </div>

              <!-- Button -->
              <button class="btn btn-primary" type="submit">Submit</button>

            </form>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div
            class="table-responsive mb-0"
          data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table class="table table-sm table-nowrap card-table" v-if="maintenanceData">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-project"
                    >
                      Date
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Maintenance Type
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Vehicle
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Paid To
                    </a>
                  </th>

                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Amount
                    </a>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="job in maintenanceData" :key="job.id">
                  <td class="goal-project">{{ job.maintenanceDate.toDate().toDateString() }}</td>
                  <td class="goal-progress">
                    {{ job.maintenanceType.toUpperCase() }}
                  </td>
                  <td class="goal-date">
                    {{ job.vehicleReg }}
                  </td>
                  <td class="text-start">
                    {{ job.paymentTo }}
                  </td>
                  <td class="text-start">
                    GH&cent; {{ job.amount }}
                  </td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <a href="#" class="dropdown-item"
            @click="getImageURL(`uploadedFiles/${transporterId}/${job.maintenanceRecieptPhoto}`)"
                              data-bs-toggle="modal"
                              data-bs-target="#imagePreviewModal">
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>
  </div>
  </div>

  <!-- Modal -->
<div class="modal fade"
id="imagePreviewModal" tabindex="-1"
aria-labelledby="imagePreviewModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="imagePreviewModalLabel">Document Preview</h5>
        <button type="button"
        class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
          <img :src="imageURL" class="img-thumbnail py-2">
      </div>
      <div class="modal-footer">
        <button type="button"
        class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <a :href="imageURL" download target="_blank">
        <button type="button" class="btn btn-primary">Download</button>
        </a>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { ref } from '@vue/runtime-dom';
import flatpickr from 'flatpickr';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { pentermFirestore, timestamp } from '../../../firebase/config';
import getUser from '../../../composables/getUser';

export default {
  name: 'Blank',
  props: [],
  components: {
    // NavCompany,
  },
  setup() {
    const {
      user, transporterId, userDetails,
    } = getUser();
    const router = useRouter();
    // const route = useRoute();
    const expenseDatePicker = ref(null);
    const maintenance = ref('');
    const vehicle = ref('');
    const maintenanceDate = ref('');
    const dataResults = ref('');
    const maintenanceData = ref('');
    const paymentTo = ref('');
    const amount = ref('');
    const comments = ref('');
    const dump = ref('');
    const error = ref('');
    const imageFiles = ref([]);
    const imageURL = ref('');

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      console.log(e.target.files);
      imageFiles.value = [...imageFiles.value,
        { fieldName: e.target.id, fileData: e.target.files },
      ];
      // console.log(imageFiles.value);
      // const fileName = imageFiles.value[0][0].name.split('.').slice(-1);
      const fileName = imageFiles.value[0].fileData[0].name.split('.').slice(-1)[0];
      console.log(fileName);
    };

    const uploadFile = (imageFile, imageName, path) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `${path}/${transporterId.value}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(uplodPercent);
      },
      (errors) => { console.log(errors.message); },
      () => { },
      storageRef.snapshot.ref.getDownloadURL().then((url) => {
        // imageURL.value = url;
        // console.log(url);
        const dumped = ref('');
        dumped.value = url;
      }));
    };

    // Submit data
    const submitData = async () => {
      const addData = {
        maintenanceType: maintenance.value,
        vehicleReg: vehicle.value,
        maintenanceDate: new Date(maintenanceDate.value),
        paymentTo: paymentTo.value,
        amount: amount.value,
        comments: comments.value,
        transporterId: transporterId.value,
        createdDate: timestamp(),
        updatedDate: timestamp(),
        uid: user.value.uid,
        userName: user.value.displayName,
      };
      await pentermFirestore.collection('transporterMaintenance').add(addData)
        .then((doc) => {
          // console.log(doc.id);
          const maintenanceRef = pentermFirestore.collection('transporterMaintenance').doc(doc.id);
          /**
           * Bulk upload files
           */
          const images = ref([]);
          imageFiles.value.forEach((data) => {
            const imageName = `${uuidv4()}.${data.fileData[0].name.split('.').slice(-1)[0]}`;
            images.value = [...images.value, { docType: data.fieldName, imageFile: imageName }];
            uploadFile(data.fileData[0], imageName, 'uploadedFiles');
            // console.log(images.value);
            const dataFieldName = data.fieldName;
            maintenanceRef.update({
              [dataFieldName]: imageName,
            });
          });
        });
      maintenanceDate.value = '';
      paymentTo.value = '';
      amount.value = '';
      comments.value = '';
      router.go({ name: 'Maintenance' });
    };

    const vehicles = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterVehicles')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .limit(25)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((vehicleData) => ({
              ...vehicleData.data(),
              id: vehicleData.id,
            }));
            // last = snap.docs[snap.docs.length - 1];
            // records.value = snap.docs.length;
            // // console.log(last.data());
            // disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };

    // const updateMaintenance = async (mID, dateData) => {
    //   const maintenanceRef = pentermFirestore
    //     .collection('transporterMaintenance')
    //     .doc(mID);
    //   const res = await maintenanceRef.update({
    //     maintenanceDate: new Date(dateData),
    //   });
    //   dump.value = res;
    // };

    const maintenanceList = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterMaintenance')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .limit(25)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            maintenanceData.value = snap.docs.map((vehicleData) => ({
              ...vehicleData.data(),
              id: vehicleData.id,
            }));
            // snap.forEach((doc) => {
            //   console.log(doc.id);
            //   console.log(doc.data().maintenanceDate);
            //   updateMaintenance(doc.id, doc.data().maintenanceDate);
            // });

            // last = snap.docs[snap.docs.length - 1];
            // records.value = snap.docs.length;
            // // console.log(last.data());
            // disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    maintenanceList();

    // delete vehicles
    const deleteVehicle = async (id) => {
      await pentermFirestore.collection('transporterMaintenance').doc(id).delete();
    };
    vehicles();
    const getImageURL = (imagePath) => {
      const storageRef = firebase.storage().ref();
      storageRef.child(imagePath).getDownloadURL()
        .then((url) => { imageURL.value = url; });
      // console.log(imageURL.value);
    };

    onMounted(() => {
      flatpickr(expenseDatePicker.value, {
        minDate: new Date().fp_incr(-14),
        maxDate: 'today',
        allowInput: true,
      });
    });
    return {
      expenseDatePicker,
      transporterId,
      fileUpload,
      user,
      uploadFile,
      submitData,
      maintenance,
      vehicle,
      maintenanceDate,
      paymentTo,
      amount,
      comments,
      dataResults,
      maintenanceData,
      deleteVehicle,
      getImageURL,
      imageURL,

    };
  },
};
</script>
<style>

</style>
