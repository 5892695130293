<template>
<!-- <NavBar /> -->
<div v-if="userDetails">
<Admin  v-if="user && userDetails.accessType == 'fleetOperator'" />
</div>
<!-- Main Content -->
  <router-view v-if="!user"/>

</template>

<script>
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
// import NavBar from './views/Nav.vue';
import Admin from './views/Admin.vue';
import getUser from './composables/getUser';

export default {
  components: {
    // NavBar,
    Admin,
  },
  props: [],
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      user, userDetails, getUserRole, getCompanyName,
    } = getUser();
    const showD = ref(false);

    return {
      user, route, router, showD, userDetails, getUserRole, getCompanyName,
    };
  },
};

</script>

<style lang="scss">
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}
//       @media (min-width: 768px) {
//         .bd-placeholder-img-lg {
//           font-size: 3.5rem;
//         }
//       }
// .content-padding {
//   margin-top: 20px;
// }
// a {
//   text-decoration: none;
//   color: #2c3e50;
// }
// a:hover {
//   color: #2c3e6e;
// }

</style>
