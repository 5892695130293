<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Job Board</h6>

            <!-- Title -->
            <h1 class="header-title">Create New Tipper Job</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <router-link class="btn btn-secondary lift"
            :to="{ name: 'TransporterJobs'}">
              Cancel
            </router-link>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
          <!-- Card -->
      <div class="card">
        <div class="card-body">
          <!-- check for vehicle -->
          <div class="container" v-if="activeVehicles.length === 0">
            <div class="py-3">
              <h1 class="display-6">Sorry you cannot continue due to:</h1>
            </div>
            <div class="py-2 px-5 text-start" style="margin: auto">
              <span class="text-primary">
                <i class="fas fa-list"></i>
                You have no vehicle registerd in this category.
              </span><br>
              <span class="text-danger">
                <i class="fas fa-exclamation-triangle"></i>
                Expired insurance or road worthiness for vehicle in this category.
              </span>
              <p class="h6">
              <router-link :to="{ name: 'TransporterVehicles'}">
                Got to vechicle dashboard
              </router-link>
              </p><br>
              <span class="text-secondary">
                <i class="fas fa-truck-moving"></i>
                Your vehicles in this category are currently on an active job.
                Mark job as completed to continue.
                </span>
              <p class="h6">
                <router-link :to="{ name: 'TransporterJobs'}">
                View jobs.
              </router-link>
              </p>

            </div>
            </div>
             <!-- form to add vehicle -->
             <div class="col-ms-12 col-md-12">
    <div class="row">
<!-- Dynamically Show  -->
<!-- end -->
<!-- Notification if vehicle is not available -->

<!-- end -->
<!--  -->
<!--  -->
  <!-- hide on default -->
  <!-- Only show if there are available vehicles -->
  <small class="error">{{ pickUpDateError }}</small>
  <div class="container col-md-3 col-md-3 px-4 py-5"
  v-if="activeVehicles.length > 0">
    <div class="row align-items-center">
      <div class="text-center text-start">
        <!-- <h1 class="display-6 font-size">Job Date</h1>
        <p>
          <input type="date" class="form-control"  v-model="jobDate" required>
        </p> -->
        <h1 class="display-6 font-size">Pickup Date</h1>
        <DatePicker
        v-model="pickupDate"
        mode="dateTime"
        :timezone="timezone"
        :max-date='new Date()'
        :min-date='new Date(2021,8,1)'
        :is-required="true"
        @click="dateClicked()"
        />
      </div>
    </div>
  </div>
<!--  -->
<!-- Only show if there are available vehicles -->
<div class="col-md-9 mx-auto col-lg-9" v-if="showForm && activeVehicles.length > 0">
        <form @submit.prevent="getDistanceMatrix"
        class="p-4 p-md-5 rounded-3 text-start">
        <!--  -->
          <div class="py-2">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <!-- truck information -->
                <div class="py-2">
                    <label >Vehicle</label>
                      <select class="form-control" required v-model="truckId">
                        <option
                        v-for="vehicle in activeVehicles" :key="vehicle.id"
                        :value="
                        {
                          truckId: vehicle.truckId,
                          Id: vehicle.id,
                          categoryName: vehicle.categoryName,
                        }">
                          {{ vehicle.truckId }} -> {{ vehicle.driverFullName }}
                        </option>
                      </select>
                </div>
                <!-- end -->
              </div>
        <!-- cost -->
            <div class="col-xs-12 col-md-4">
                   <div class="py-2 field-margin">
                      <label>Sales GH&cent;</label>
                      <input type="number" step=".01" class="form-control" v-model="tripPrice"
                       required>
                    </div>
              </div>
              </div>
              <div class="row">
                  <div class="col-xs-12 col-md-4">
                   <div class="py-2 field-margin">
                      <label>Expenses GH&cent;</label>
                      <input type="number" step=".01" class="form-control" v-model="jobExpenses"
                       required>
                    </div>
                    </div>
                  <div class="col-xs-12 col-md-4">
                   <div class="py-2 field-margin">
                      <label>Maintenance GH&cent;</label>
                      <input type="number" step=".01" class="form-control" v-model="jobMaintenace"
                       required>
                    </div>
                    </div>
                     <div class="col-xs-12 col-md-4">
                      <div class="py-2 field-margin">
                        <label>Payment Status</label>
                        <select class="form-control" v-model="paymentStatus">
                          <option value="Pending">Pending</option>
                          <option value="Received">Received</option>
                        </select>
                      </div>
                     </div>
                    </div>
              <!-- additional info -->
        <div class="py-2 col-xs-12 col-md-12">
          <label for="itemdesc">&nbsp; Additional comments</label>
          <textarea v-model="jobComments" class="form-control"
          placeholder="Leave a comment here"
          id="itemdesc" style="height: 100px"></textarea>
          </div>
        </div>
        <!-- end desc -->
          <div class="checkbox mb-3">
            <small class="error">{{ warning }}</small>
            <small class="error">{{ error }}</small>
          </div>
          <div class="row text-center">
            <div class="col-sm-6">
                <router-link class="btn btn-secondary"
                  :to="{ name: 'TransporterJobs'}">
                  Cancel
                </router-link>
            </div>
            <div class="col-sm-6">
                <button class="w-10 btn btn-danger" type="submit">Save</button>
            </div>
              <!-- <button class="w-10 btn btn-danger" type="submit">Book Pickup</button> -->
          </div>

        </form>
      </div>
<!--  -->
<!--  -->
<!--  -->
    </div>
  </div>

        </div>
      </div>
  </div>

  <div class="container-fluid">
  <div class="row">
</div>
</div>
</template>

<script>
import {
  defineComponent, onMounted, ref,
} from 'vue';
// import VueGoogleAutocomplete from 'vue-google-autocomplete';

// import firebase from 'firebase/app';
import { useRouter } from 'vue-router';
import { DatePicker } from 'v-calendar';
import getUser from '../../../composables/getUser';
import { timestamp, pentermFirestore } from '../../../firebase/config';
// import usePrice from '../../../composables/getPrice';

export default defineComponent({
  components: {
    // VueGoogleAutocomplete,
    DatePicker,
  },
  setup() {
    // const { getPrice } = usePrice();
    const {
      user, userDetails, getUserRole,
    } = getUser();
    const long = ref('');
    const lat = ref('');
    const jobDate = ref('');
    const pickup = ref('');
    const pickUpContact = ref('');
    const pickUpContactPhone = ref('');
    const dropoff = ref('');
    const dropoffContact = ref('');
    const dropoffContactPhone = ref('');
    const pickupDate = ref('');
    const dropoffDate = ref('');
    const jobDetail = ref('');
    const pickupLocation = ref(null);
    const dropoffLocation = ref(null);
    const tripPrice = ref('');
    const error = ref('');
    const warning = ref('');
    const customer = ref('');
    const customerPhone = ref(null);
    const customerEmail = ref('');
    const tripCost = ref('');
    const fberror = ref('');
    const router = useRouter();
    const trucks = ref('');
    const truckId = ref('');
    const weight = ref('');
    const weightOfBag = ref('');
    const noOfBags = ref('');
    const totalTonnage = ref('');
    const itemDesc = ref('');
    const fuelCost = ref('');
    const goodsWeight = 0;
    const picked = ref(new Date());
    const exampleTo = ref(new Date());
    const showOrderForm = ref(false);
    const vehicleType = ref('');
    const vehicleTypeImage = ref('');
    const intTelNum = ref(null);
    const timezone = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const showForm = ref(false);
    const activeVehicles = ref('');
    const activeVehiclesId = ref('');
    const pickUpDateError = ref('');
    const driverEmail = ref('');
    const jobMaintenace = ref('');
    const jobExpenses = ref('');
    const paymentStatus = ref('');
    const jobComments = ref('');
    const ownerEmail = ref('');

    const getVehicleSelected = (vehicleSelected) => {
      activeVehiclesId.value = vehicleSelected;
    };
    //
    /**
     * Get Vehicle Categories
     */
    const priceSchedules = async () => {
      try {
        const res = await pentermFirestore.collection('vehicleCategories').orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            dataResults.value = snap.docs.map((priceSchedule) => (
              { ...priceSchedule.data(), id: priceSchedule.id }
            ));
          });
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    // End
    // Get available vehicles with valid insurance and mot and not on an active job.
    const pentermVehicles = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles')
          .where('categoryName', '==', 'Tipper')
          .where('transporterId', '==', userDetails.value.transporterId)
          // .where('jobStatus', '==', 0)
          .onSnapshot((snap) => {
            activeVehicles.value = snap.docs.map((vehicles) => (
              { ...vehicles.data(), id: vehicles.id }
            ));
          });
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };

    // Show form if valid date is selected.
    const dateClicked = () => {
      showForm.value = true;
    };

    const getPickupAddressData = (addressData, placeResultData) => {
      pickup.value = {
        location: placeResultData.name,
        lat: addressData.latitude,
        lng: addressData.longitude,
        place_id: placeResultData.place_id,
        country: placeResultData.country,
        locality: placeResultData.locality,
        region: placeResultData.administrative_area_level_1,
      };
    };

    const getDropoffAddressData = (addressData, placeResultData) => {
      dropoff.value = {
        location: placeResultData.name,
        lat: addressData.latitude,
        lng: addressData.longitude,
        place_id: placeResultData.place_id,
        country: placeResultData.country,
        locality: placeResultData.locality,
        region: placeResultData.administrative_area_level_1,
      };
    };

    const { google } = window;

    const getDistanceMatrixAuto = () => {
      // const origin = new google.maps.LatLng(pickup.value.lat, pickup.value.lng);
      const destination = new google.maps.LatLng(dropoff.value.lat, dropoff.value.lng);
      const depart = new Date();
      // const departTime = depart.getTime() / 1000;
      if (pickup.value.place_id && dropoff.value.place_id) {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            drivingOptions: {
              departureTime: depart,
            },
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          (response, status) => {
            if (status !== 'OK') {
              // console.log(`Error was: ${status}`);
              error.value = 'Please ensure a pickup location, date and destination is selected.';
            } else {
              dump.value = response;
            }
          },
        );
      }
    };

    // Add Order to DB
    const getDistanceMatrix = () => {
      // Details
      const customerName = 'Tipper Customer';
      const customerPhoneNo = '000';
      const customerEmailAdd = 'no-email@penterm.com';
      const pickUpContactName = 'None';
      const pickUpContactPhoneNo = 'None';
      const dropoffContactName = 'None';
      const dropoffContactPhoneNo = 'None';
      let addTrip = {};
      const depart = new Date(pickupDate.value);

      // End
      addTrip = {
        tripId: `TP${Math.floor(Math.random() * 10)}${Date.now()}`,
        jobDate: depart,
        customer: customerName,
        phone: customerPhoneNo,
        email: customerEmailAdd,
        pickup: 'None',
        pickupCoordinates: '',
        pickupDate: depart,
        pickUpContact: pickUpContactName,
        pickUpContactPhone: pickUpContactPhoneNo,
        dropoff: 'None',
        dropoffCoordinates: '',
        dropoffContact: dropoffContactName,
        dropoffContactPhone: dropoffContactPhoneNo,
        distance: 0,
        itemDesc: itemDesc.value,
        itemWeightDesc: weight.value,
        itemWeight: goodsWeight,
        noOfBags: noOfBags.value,
        weightOfBag: weightOfBag.value,
        computedCost: tripPrice.value,
        cost: tripPrice.value,
        finalRevenue: tripPrice.value,
        truckRegistration: truckId.value.truckId,
        truckUid: truckId.value.Id,
        driverEmail: driverEmail.value,
        ownerEmail: ownerEmail.value,
        fuelCost: fuelCost.value,
        finalFuel: fuelCost.value,
        vehicleType: vehicleType.value,
        vehicleTypeImage: vehicleTypeImage.value,
        jobExpenses: jobExpenses.value,
        jobMaintenace: jobMaintenace.value,
        paymentStatus: paymentStatus.value,
        jobComments: jobComments.value,
        username: user.value.displayName,
        transporterId: userDetails.value.transporterId,
        uid: user.value.uid,
        status: 9,
        createdDate: timestamp(),
        updatedDate: timestamp(),
      };
      const submitData = async () => {
        const res = await pentermFirestore.collection('transporterJobs').add(addTrip);
        fberror.value = res.message;

        // Update truck status to prevent new jobs being added.
        const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(truckId.value.Id);
        await vehicleRef.update({
          jobStatus: 1,
        });
        // end
      };
        // end
      submitData();
      // console.log(user.displayName);
      // console.log(addTrip);
      if (!fberror.value) {
        // console.log('Added to db');
        router.push({ name: 'TransporterJobs' });
      }
      // Clear fields after submission
      pickupDate.value = '';
      tripCost.value = '';
      customer.value = '';
      customerPhone.value = '';
      customerEmail.value = '';
      fuelCost.value = '';
      pickupLocation.value.clear();
      dropoffLocation.value.clear();
    };

    onMounted(() => {
      priceSchedules();
      pentermVehicles();
      if (getUserRole.value === 'admin') {
        priceSchedules();
        pentermVehicles();
      } else if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });

    return {
      pickUpDateError,
      dataResults,
      activeVehicles,
      pentermVehicles,
      long,
      lat,
      jobDate,
      intTelNum,
      timezone,
      pickup,
      pickUpContact,
      pickUpContactPhone,
      dropoff,
      dropoffContact,
      dropoffContactPhone,
      getPickupAddressData,
      getDropoffAddressData,
      tripPrice,
      pickupDate,
      dropoffDate,
      jobDetail,
      pickupLocation,
      dropoffLocation,
      error,
      customer,
      customerEmail,
      customerPhone,
      tripCost,
      jobMaintenace,
      jobExpenses,
      jobComments,
      user,
      userDetails,
      fberror,
      getDistanceMatrix,
      router,
      trucks,
      truckId,
      weight,
      weightOfBag,
      noOfBags,
      totalTonnage,
      fuelCost,
      itemDesc,
      picked,
      exampleTo,
      warning,
      showOrderForm,
      showForm,
      vehicleType,
      getDistanceMatrixAuto,
      dateClicked,
      getVehicleSelected,
    };
  },
});

</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.font-size{
  font-size: 150%;
}
.vehicle-type {
  cursor: pointer;
  border: 1px solid #ccc;
}
.vehicle-type:hover {
  border: 1px solid #c90016;
}
.vehicle-type-selected {
  border: 1px solid #c90016;
}
</style>
