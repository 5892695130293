<template>
<!--  -->
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Vehicle Edit</h6>

            <!-- Title -->
            <h1 class="header-title">Vehicle Registration: {{ vehicleReg }}</h1>
            <div>
              Year: <span class="text-muted"> {{ vehicleMakeYear }}</span>
              Make: <span class="text-primary"> {{ vehicleMake }}</span>
              VIN: <span class="text-primary"> {{ vehicleVIN }}</span>
            </div>
          </div>
          <div class="col-auto" v-if="editDriver">
            <!-- Button -->
            <a href="#!" class="btn btn-secondary lift" @click="showDriverDetails"> Cancel </a>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
        <!-- Alert -->
        <div class="alert alert-info alert-dismissible fade show"
        role="alert" v-if="showSuccessAlert">
          <strong>{{showSuccessMessage}}!</strong> Click close to dismiss this message.

          <!-- Button -->
          <button type="button" class="btn-close"
          data-bs-dismiss="alert" aria-label="Close"></button>

        </div>
    <div class="row">
      <div class="col-12 col-lg-12">
        <div class="card">
          <div class="card-body">
        <form @submit.prevent="updateVehicle">
          <div class="row py-2">
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <select class="form-control"
                placeholder="Region" required v-model="vehicleMake">
                  <option value="">Select</option>
                  <option value="Scania">Scania</option>
                  <option value="Hino">Hino</option>
                  <option value="Iveco">Iveco</option>
                  <option value="MAN">MAN</option>
                  <option value="Volvo">Volvo</option>
                  <option value="Dongfeng">Dongfeng</option>
                  <option value="TATA">TATA</option>
                  <option value="Volkswagen">Volkswagen</option>
                  <option value="Daimler">Daimler</option>
                </select>
                <label>Manufacturer <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="vehicleReg">
                <label>Registration No. <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
                <input type="text" class="form-control"
                required v-model="vehicleVIN">
                <label>Chassis Number <span class="required-field"> *</span></label>
              </div>
            </div>
            <!--  -->
            <div class="col-xs-12 col-md-3">
              <div class="form-floating mb-3">
              <select class="form-control"
                required v-model="vehicleMakeYear">
                <option v-for="year in makeYear" :key="year" :value="year">{{ year }}</option>
              </select>
                <label>Year <span class="required-field"> *</span></label>
              </div>
            </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="btn btn-danger">Update</button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
        <!-- .end of alert -->
        <div class="row" v-if="!editDriver && insureStart">
          <!-- Vehicle Details -->
            <div class="col-6">
              <!-- Insurance -->
                <div class="card">
                  <div class="card-header alert-dark">
                    <div class="row align-items-center">
                      <div class="col">
                        <!-- Title -->
                        <h4 class="card-header-title">Vehicle Insurance</h4>
                      </div>
                      <div class="col-auto">
                        <!-- Button -->
                        From: {{ insureStart.toDate().toDateString() }}
                      </div>
                      <div class="col-auto">
                        <!-- Button -->
                        To: {{ insureEnd.toDate().toDateString() }}
                      </div>
                    </div>
                    <!-- / .row -->
                  </div>
                  <div class="card-body">
                    <form @submit.prevent="updateVehicleInsurance">
                      <div class="row py-2 text-start">
                        <!--  -->
                        <div class="col-xs-12 col-md-6">
                    <label>Company</label>
                    <select class="form-control"
                    placeholder="Region" required v-model="insuranceCompany">
                      <option value="">Select</option>
                      <option value="Activa International">Activa International </option>
                      <option value="Allianz Insurance">Allianz Insurance</option>
                      <option value="Bedrock Insurance">Bedrock Insurance</option>
                      <option value="Best Assurance">Best Assurance</option>
                      <option value="Donewell Insurance">Donewell Insurance</option>
                      <option value="Enterprise Insurance">Enterprise Insurance</option>
                      <option value="Ghana Union Assurance">Ghana Union Assurance </option>
                      <option value="Glico General Insurance">Glico General Insurance</option>
                      <option value="Hollard Insurance">Hollard Insurance</option>
                      <option value="Imperial General Assurance">Imperial General Assurance</option>
                      <option value="Loyalty Insurance">Loyalty Insurance </option>
                      <option value="Millennium Insurance">Millennium Insurance</option>
                      <option value="NSIA Insurance">NSIA Insurance</option>
                      <option value="Phoenix Insurance">Phoenix Insurance</option>
                      <option value="Prime Insurance ">Prime Insurance</option>
                      <option value="Priority Insurance">Priority Insurance</option>
                      <option value="Provident Insurance">Provident Insurance</option>
                      <option value="Quality Insurance">Quality Insurance</option>
                      <option value="RegencyNem Insurance">RegencyNem Insurance</option>
                      <option value="Saham Insurance">Saham Insurance</option>
                      <option value="Serene Insurance">Serene Insurance</option>
                      <option value="SIC Insurance">SIC Insurance</option>
                      <option value="Star Assurance">Star Assurance</option>
                      <option value="SUNU Assurances">SUNU Assurances</option>
                      <option value="Unique Insurance">Unique Insurance</option>
                      <option value="Vanguard Assurance">Vanguard Assurance</option>
                      <option value="Wapic Insurance">Wapic Insurance</option>
                    </select>
                        </div>
                        <!--  -->
                        <div class="col-xs-12 col-md-6">
                            <label >Insurance Type</label>
                            <select class="form-control"
                            placeholder="Region" required v-model="insuranceType">
                              <option value="">Select</option>
                              <option value="Comprehensive">Comprehensive</option>
                              <option value="3rd-Party">3rd-Party</option>
                            </select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xs-12 col-md-6">
                          <label >Insurance Start</label>
                          <input type="date" class="form-control"
                          :class="[insuranceErrorStyle]"
                          placeholder="Start date" required v-model="insuranceStart"
                          :max="maxDate" :min="minDate"
                          >
                      </div>
                      <div class="col-xs-12 col-md-6">
                        <h4 class="text-primary">
                          Insurance Certificate
                          <span class="required-field"> *</span>
                          </h4>
                          <label>
                            <input type="file" class="form-control"
                            id="insurancePhoto" name="insurancePhoto" required
                            capture="user" accept="image/*" @change="fileUpload">
                        </label>
                      </div>
                      </div>
                      <div class="col-xs-12 col-md-12 py-2 text-end">
                        <button class="btn btn-primary" type="submit">
                          <span class="h2 fe fe-save"></span> Update
                          </button>
                      </div>
                    </form>
                  </div>
                  <!-- Footer -->
                  <div class="card-footer card-footer-boxed">
                    <div class="row align-items-center justify-content-between">
                      <div class="col-auto">

                        <!-- Status -->
                        <small>
                          <button type="button"
                          @click="getImageURL(`images/${transporterId}/${insurancePhoto}`)"
                          class="btn btn-sm btn-white" data-bs-toggle="modal"
                          data-bs-target="#imagePreviewModal">
                            View Certificate
                          </button>
                        </small>

                      </div>
                    </div>
                  </div>
                </div>
              <!-- .row end -->
              <!-- Mot -->
                <div class="card">
                  <div class="card-header alert-info">
                    <div class="row align-items-center">
                      <div class="col">
                        <!-- Title -->
                        <h4 class="card-header-title">Road Worthiness/MOT</h4>
                      </div>
                      <div class="col-auto">
                        <!-- Button -->
                        From: {{ roadTaxStart.toDate().toDateString() }}
                      </div>
                      <div class="col-auto">
                        <!-- Button -->
                        To: {{ roadTaxEnd.toDate().toDateString() }}
                      </div>
                    </div>
                    <!-- / .row -->
                  </div>
                  <div class="card-body">
                    <form @submit.prevent="updateVehicleMot">
                      <div class="row py-2 text-start">
                        <div class="col-xs-12 col-md-6">
                          <label>Road Worthiness Start</label>
                            <input type="date" class="form-control"
                            :class="[motErrorStyle]"
                            placeholder="Start date" required v-model="motStart"
                            :max="maxDate" :min="minDate"
                            >
                        </div>
                        <div class="col-xs-12 col-md-6">
                          <h4 class="text-primary">
                            Road Worthy Certificate
                            <span class="required-field"> *</span>
                            </h4>
                            <label>
                              <input type="file" class="form-control"
                              id="roadWorthyPhoto" name="roadWorthyPhoto" required
                              capture="user" accept="image/*" @change="fileUpload">
                          </label>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-12 py-2 text-end">
                        <button class="btn btn-primary" type="submit">
                          <span class="h2 fe fe-save"></span> Update
                          </button>
                      </div>
                    </form>
                  </div>
                  <!-- Footer -->
                  <div class="card-footer card-footer-boxed">
                    <div class="row align-items-center justify-content-between">
                      <div class="col-auto">

                        <!-- Status -->
                        <small>
                          <button type="button"
                          @click="getImageURL(`images/${transporterId}/${roadWorthyPhoto}`)"
                          class="btn btn-sm btn-white" data-bs-toggle="modal"
                          data-bs-target="#imagePreviewModal">
                            View Certificate
                          </button>
                        </small>

                      </div>
                    </div>
                  </div>
                  <!-- fotter end -->
                </div>
              <!-- .row end -->
            </div>
      <!-- .end -->
      <div class="col-6">
        <!-- Goods In-Transit Insurance -->
            <div class="card">
                  <div class="card-header alert-secondary">
                    <div class="row align-items-center">
                      <div class="col">
                        <!-- Title -->
                        <h4 class="card-header-title">Goods In-Transit Insurance</h4>
                      </div>
                      <div class="col-auto">
                        <!-- Button -->
                        From: {{ goodsIntrasnsitStart.toDate().toDateString() }}
                      </div>
                      <div class="col-auto">
                        <!-- Button -->
                        To: {{ goodsIntrasnsitEnd.toDate().toDateString() }}
                      </div>
                    </div>
                    <!-- / .row -->
                  </div>
              <div class="card-body">
                  <form @submit.prevent="updateGITInsurance">
                  <div class="row text-start">
                    <div class="col-xs-12 col-md-6">
                        <label>Company
                          <span class="required-field"> *</span></label>
                      <select class="form-control"
                      placeholder="Region" required v-model="GITinsuranceCompany">
                        <option value="">Select</option>
                      <option value="Activa International">Activa International </option>
                      <option value="Allianz Insurance">Allianz Insurance</option>
                      <option value="Bedrock Insurance">Bedrock Insurance</option>
                      <option value="Best Assurance">Best Assurance</option>
                      <option value="Donewell Insurance">Donewell Insurance</option>
                      <option value="Enterprise Insurance">Enterprise Insurance</option>
                      <option value="Ghana Union Assurance">Ghana Union Assurance </option>
                      <option value="Glico General Insurance">Glico General Insurance</option>
                      <option value="Hollard Insurance">Hollard Insurance</option>
                      <option value="Imperial General Assurance">Imperial General Assurance</option>
                      <option value="Loyalty Insurance">Loyalty Insurance </option>
                      <option value="Millennium Insurance">Millennium Insurance</option>
                      <option value="NSIA Insurance">NSIA Insurance</option>
                      <option value="Phoenix Insurance">Phoenix Insurance</option>
                      <option value="Prime Insurance ">Prime Insurance</option>
                      <option value="Priority Insurance">Priority Insurance</option>
                      <option value="Provident Insurance">Provident Insurance</option>
                      <option value="Quality Insurance">Quality Insurance</option>
                      <option value="RegencyNem Insurance">RegencyNem Insurance</option>
                      <option value="Saham Insurance">Saham Insurance</option>
                      <option value="Serene Insurance">Serene Insurance</option>
                      <option value="SIC Insurance">SIC Insurance</option>
                      <option value="Star Assurance">Star Assurance</option>
                      <option value="SUNU Assurances">SUNU Assurances</option>
                      <option value="Unique Insurance">Unique Insurance</option>
                      <option value="Vanguard Assurance">Vanguard Assurance</option>
                      <option value="Wapic Insurance">Wapic Insurance</option>
                      </select>
                      </div>
                    <!--  -->
                    <div class="col-xs-12 col-md-6">
                        <label>Insurance Start</label>
                        <input type="date" class="form-control" :min="minDate" :max="maxDate"
                        :class="[insuranceErrorStyle]"
                        required v-model="GITinsuranceStart"
                        >
                    </div>
                  </div>
                  <div class="row py-2">
                    <div class="col-xs-12 col-md-6">
                      <h4 class="text-primary">
                        Goods in Transit Insurance
                        <span class="required-field"> *</span>
                        </h4>
                        <label>
                          <input type="file" id="goodsInTransitPhoto"
                          name="Goods in Transit Insurance" required class="form-control"
                          capture="user" accept="image/*" @change="fileUpload" ref="docName">
                      </label>
                    </div>
                  </div>
                      <div class="col-xs-12 col-md-12 py-2 text-end">
                        <button class="btn btn-primary" type="submit">
                          <span class="h2 fe fe-save"></span> Update
                          </button>
                      </div>
                </form>
              </div>
                  <!-- Footer -->
                  <div class="card-footer card-footer-boxed">
                    <div class="row align-items-center justify-content-between">
                      <div class="col-auto">

                        <!-- Status -->
                        <small>
                          <button type="button"
                          @click="getImageURL(`images/${transporterId}/${goodsInTransitPhoto}`)"
                          class="btn btn-sm btn-white" data-bs-toggle="modal"
                          data-bs-target="#imagePreviewModal">
                            View Certificate
                          </button>
                        </small>

                      </div>
                    </div>
                  </div>
                  <!-- fotter end -->
            </div>
          <!-- .row end -->
          <!--  Vehicle Income Tax -->
            <div class="card">
                  <div class="card-header alert-light">
                    <div class="row align-items-center">
                      <div class="col">
                        <!-- Title -->
                        <h4 class="card-header-title">Vehicle Income Tax</h4>
                      </div>
                      <div class="col-auto">
                        <!-- Button -->
                        From:  {{ vehicleIncomeTaxStart.toDate().toDateString() }}
                      </div>
                      <div class="col-auto">
                        <!-- Button -->
                        To:  {{ vehicleIncomeTaxEnd.toDate().toDateString() }}
                      </div>
                    </div>
                    <!-- / .row -->
                  </div>
              <div class="card-body">
                  <form @submit.prevent="updateVIT">
                  <div class="row text-start">
                    <div class="col-xs-12 col-md-6">
                      <div class="mb-3">
                        <label>Start Date</label>
                        <input type="date" class="form-control"
                        :class="[motErrorStyle]"
                        required v-model="VITStart" :min="minDate" :max="maxDate"
                        >
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-6">
                    <h4 class="text-primary">
                      Vehicle Income Tax
                      <span class="required-field"> *</span>
                      </h4>
                      <label>
                        <input type="file" id="vehiclIncomeTaxPhoto"
                        name="Vehicle Income Tax" required class="form-control"
                        capture="user" accept="image/*" @change="fileUpload" ref="docName">
                    </label>
                  </div>
                  </div>
                      <div class="col-xs-12 col-md-12 py-2 text-end">
                        <button class="btn btn-primary" type="submit">
                          <span class="h2 fe fe-save"></span> Update
                          </button>
                      </div>
                </form>
              </div>
                  <!-- Footer -->
                  <div class="card-footer card-footer-boxed">
                    <div class="row align-items-center justify-content-between">
                      <div class="col-auto">

                        <!-- Status -->
                        <small>
                          <button type="button"
                          @click="getImageURL(`images/${transporterId}/${vehiclIncomeTaxPhoto}`)"
                          class="btn btn-sm btn-white" data-bs-toggle="modal"
                          data-bs-target="#imagePreviewModal">
                            View Certificate
                          </button>
                        </small>

                      </div>
                    </div>
                  </div>
                  <!-- fotter end -->
            </div>
          <!-- .row end -->
      </div>
  </div>

<!--  -->
  <!-- Driver Form -->

<!-- Modal -->
<div class="modal fade"
id="imagePreviewModal" tabindex="-1"
aria-labelledby="imagePreviewModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="imagePreviewModalLabel">Document Preview</h5>
        <button type="button"
        class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
          <img :src="imageURL" class="img-thumbnail py-2">
      </div>
      <div class="modal-footer">
        <button type="button"
        class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <a :href="imageURL" download target="_blank">
        <button type="button" class="btn btn-primary">Download</button>
        </a>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import {
  ref, onMounted,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase';
import { useRoute, useRouter } from 'vue-router';
import getUser from '../../composables/getUser';
import { timestamp, pentermFirestore } from '../../firebase/config';

export default ({
  components: {
  },
  setup() {
    const { user, getUserRole, transporterId } = getUser();
    const router = useRouter();
    const route = useRoute();
    // const fberror = ref('');
    const dataResults = ref('');
    const dump = ref('');
    const error = ref('');
    const publicPath = process.env.BASE_URL;
    const categoryPicked = ref('');
    const vehicleMake = ref('');
    const vehicleReg = ref('');
    const vehicleVIN = ref('');
    const vehicleMakeYear = ref('');
    const makeYear = ref([]);
    const insuranceCompany = ref('');
    const insuranceType = ref('');
    const insuranceStart = ref('');
    const insuranceEnd = ref('');
    const motStart = ref('');
    const motEnd = ref('');
    const driverSex = ref('');
    const driverFullName = ref('');
    const driverDoB = ref('');
    const driverPhone = ref('');
    const driverLicenseType = ref('');
    const driverLicenseNumber = ref('');
    const driverLicenseExpiry = ref('');
    const driverEmail = ref('');
    const addressGPS = ref('');
    const addressPostal = ref('');
    const driverRegion = ref('');
    const dateResults = ref('');
    const dobError = ref('');
    const dobErrorStyle = ref('');
    const licenseError = ref('');
    const licenseErrorStyle = ref('');
    const insuranceError = ref('');
    const insuranceErrorStyle = ref('');
    const GITinsuranceCompany = ref('');
    const GITinsuranceStart = ref('');
    const GITinsuranceEnd = ref('');
    const VITStart = ref('');
    const VITEnd = ref('');
    const motError = ref('');
    const motErrorStyle = ref('');
    const userGuard = ref('');
    const vehicleDocuments = ref('');
    const minDate = ref('');
    const maxDate = ref('');
    const newDay = ref('');
    const newMonth = ref('');
    const imageFiles = ref([]);
    const dobMaxYear = ref([]);
    const editDriver = ref(false);
    // Insurance Details
    const insureStart = ref('');
    const insureEnd = ref('');
    const insurancePhoto = ref('');
    const roadTaxStart = ref('');
    const roadTaxEnd = ref('');
    const roadWorthyPhoto = ref('');
    const goodsIntrasnsitStart = ref('');
    const goodsIntrasnsitEnd = ref('');
    const goodsInTransitPhoto = ref('');
    const vehicleIncomeTaxStart = ref('');
    const vehicleIncomeTaxEnd = ref('');
    const vehiclIncomeTaxPhoto = ref('');
    // image
    const imageURL = ref('');
    // alerts
    const showSuccessAlert = ref(false);
    const showSuccessMessage = ref('');

    // Function to format dates
    const formatDate = (date) => {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) { month = `0${month}`; }
      if (day.length < 2) { day = `0${day}`; }

      return [year, month, day].join('-');
    };

    const showDriverDetails = () => {
      editDriver.value = !editDriver.value;
    };

    const getDate = () => {
      /**
     * Function to get minimum and maximum dates for selection
     */
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      // const date = new Date(year - 1, month, day);

      if (day < 10) {
        newDay.value = `0${day}`;
      } else {
        newDay.value = day;
      }
      if (month < 10) {
        newMonth.value = `0${month}`;
      } else {
        newMonth.value = month;
      }
      minDate.value = `${year - 1}-${newMonth.value}-${newDay.value}`;
      maxDate.value = `${year + 1}-${newMonth.value}-${newDay.value}`;
      dobMaxYear.value = `${year - 19}-${newMonth.value}-${newDay.value}`;
    };
    // End
    const getEndDate = (date, months) => {
    /**
     * Get End date from Start Date
     */
      const newDate = new Date(date);
      const d = newDate.getDate();
      newDate.setMonth(newDate.getMonth() + +months);
      if (newDate.getDate() !== d) {
        newDate.setDate(0);
      }
      return newDate;
    };
    // End

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      // console.log(e.target.name);
      imageFiles.value = [...imageFiles.value,
        { fieldName: e.target.id, fileData: e.target.files },
      ];
      // console.log(imageFiles.value);
    };

    const uploadFile = (imageFile, imageName) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `images/${transporterId.value}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(uplodPercent);
      },
      (errors) => { console.log(errors.message); },
      () => { },
      storageRef.snapshot.ref.getDownloadURL().then((url) => {
        // imageURL.value = url;
        // console.log(url);
        const dumped = ref('');
        dumped.value = url;
      }));
    };

    // Get vehicle selected for editing
    const getVehicle = async () => {
      try {
        const res = await pentermFirestore.collection('transporterVehicles').doc(route.params.id).get();
        if (!res.exists) {
          throw Error('That trip does not exists');
        }
        vehicleMake.value = res.data().vehicleMake;
        vehicleReg.value = res.data().vehicleReg;
        vehicleVIN.value = res.data().vehicleVIN;
        vehicleMakeYear.value = res.data().vehicleMakeYear;
        insuranceCompany.value = res.data().insuranceCompany;
        insuranceType.value = res.data().insuranceType;
        insuranceStart.value = formatDate(res.data().insuranceStart.toDate());
        insuranceEnd.value = formatDate(res.data().insuranceEnd.toDate());
        GITinsuranceCompany.value = res.data().goodsInTransitInsuranceCompany;
        GITinsuranceStart.value = formatDate(res.data().goodsInTransitInsuranceStart.toDate());
        GITinsuranceEnd.value = formatDate(res.data().goodsInTransitInsuranceEnd.toDate());
        VITStart.value = formatDate(res.data().vehiclIncomeTaxStart.toDate());
        VITEnd.value = formatDate(res.data().vehiclIncomeTaxEnd.toDate());
        motStart.value = formatDate(res.data().motStart.toDate());
        motEnd.value = formatDate(res.data().motEnd.toDate());
        vehicleDocuments.value = res.data().documents;
        userGuard.value = res.data().uid;
        // Insurance details
        insureStart.value = res.data().insuranceStart;
        insureEnd.value = res.data().insuranceEnd;
        insurancePhoto.value = res.data().insurancePhoto;
        roadTaxStart.value = res.data().motStart;
        roadTaxEnd.value = res.data().motEnd;
        roadWorthyPhoto.value = res.data().roadWorthyPhoto;
        goodsIntrasnsitStart.value = res.data().goodsInTransitInsuranceStart;
        goodsIntrasnsitEnd.value = res.data().goodsInTransitInsuranceEnd;
        goodsInTransitPhoto.value = res.data().goodsInTransitPhoto;
        vehicleIncomeTaxStart.value = res.data().vehiclIncomeTaxStart;
        vehicleIncomeTaxEnd.value = res.data().vehiclIncomeTaxEnd;
        vehiclIncomeTaxPhoto.value = res.data().vehiclIncomeTaxPhoto;
      } catch (err) {
        error.value = err.message;
      }
    };

    // Get the list of make year from current year
    const currentDate = new Date();
    const currentDateYear = currentDate.getFullYear() + 1;

    for (let year = (currentDateYear - 12); year < currentDateYear; year += 1) {
      makeYear.value.push(year);
    }

    const getNumberOfDays = (start, end, type) => {
      const date1 = new Date(start);
      const date2 = new Date(end);
      const today = new Date();
      // const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const age = Math.round(diffInTime / oneDay / 365);
      // const months = Math.round(diffInTime / oneDay / 30);

      // Errors
      // Dob Error
      if (type === 'DoB' && age < 18) {
        dobError.value = 'Driver not old enough';
        dobErrorStyle.value = 'date-error';
      } else {
        dobError.value = '';
        dobErrorStyle.value = '';
      }
      // License Expiry Error
      if (type === 'License' && age < 2) {
        licenseError.value = 'Driver license is less than 2 years';
        licenseErrorStyle.value = 'date-error';
      } else {
        licenseError.value = '';
        licenseErrorStyle.value = '';
      }
      /**
       * Insurance Expiry Error
       * Expiry date must not be in the past
       */
      if (type === 'Insurance' && today > date2) {
        insuranceError.value = 'Insurance is not valid';
        insuranceErrorStyle.value = 'date-error';
      } else {
        insuranceError.value = '';
        insuranceErrorStyle.value = '';
      }
      /**
       * Mot Expiry Error
       * Expiry date must not be in the past
       */
      if (type === 'MoT') {
        if (today > date2 && date2 > date1) {
          motError.value = 'Road Worthy is not valid';
          motErrorStyle.value = 'date-error';
        } else if (date2 <= date1) {
          motError.value = 'Road Worthy is not valid';
          motErrorStyle.value = 'date-error';
        }
      }
    };

    // Submit update
    const updateVehicle = async () => {
      /**
       * Update Vehicles
       */
      // end of upload
      if (user.value.uid === userGuard.value) {
        const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(route.params.id);
        await vehicleRef.update({
          vehicleReg: vehicleReg.value,
          vehicleVIN: vehicleVIN.value,
          vehicleMake: vehicleMake.value,
          vehicleMakeYear: vehicleMakeYear.value,
          updatedDate: timestamp(),
          updatedBy: user.value.displayName,
        });
        showSuccessMessage.value = 'Vehicle Updated';
        showSuccessAlert.value = true;
        router.go({ name: 'TransporterVehicleEdit', params: { id: route.params.id } });
      } else {
        router.push({ name: 'Error' });
      }
    };
    // Submit update
    const updateVehicleInsurance = async () => {
      // ensure only the specified user can edit the data
      /**
       *
       */
      if (user.value.uid === userGuard.value) {
        const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(route.params.id);
        await vehicleRef.update({
          insuranceCompany: insuranceCompany.value,
          insuranceType: insuranceType.value,
          insuranceStart: new Date(insuranceStart.value),
          insuranceEnd: getEndDate(insuranceStart.value, 11),
          updatedDate: timestamp(),
          updatedBy: user.value.displayName,
        });
        // Upload image
        imageFiles.value.forEach((data) => {
          const imageName = `${uuidv4()}.jpeg`;
          uploadFile(data.fileData[0], imageName);
          // console.log(images.value);
          const dataFieldName = data.fieldName;
          vehicleRef.update({
            [dataFieldName]: imageName,
          });
        });
        showSuccessMessage.value = 'Vehicle Insurance Updated';
        showSuccessAlert.value = true;
        router.go({ name: 'TransporterVehicleEdit', params: { id: route.params.id } });
      } else {
        router.push({ name: 'Error' });
      }
    };
    // Update Road MOT
    const updateVehicleMot = async () => {
      // ensure only the specified user can edit the data
      /**
       *
       */
      if (user.value.uid === userGuard.value) {
        const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(route.params.id);
        await vehicleRef.update({
          motStart: new Date(motStart.value),
          motEnd: getEndDate(motStart.value, 11),
          updatedDate: timestamp(),
          updatedBy: user.value.displayName,
        });
        // Upload image
        imageFiles.value.forEach((data) => {
          const imageName = `${uuidv4()}.jpeg`;
          uploadFile(data.fileData[0], imageName);
          // console.log(images.value);
          const dataFieldName = data.fieldName;
          vehicleRef.update({
            [dataFieldName]: imageName,
          });
        });
        showSuccessMessage.value = 'Road Worthiness/MOT Updated';
        showSuccessAlert.value = true;
        router.go({ name: 'TransporterVehicleEdit', params: { id: route.params.id } });
      } else {
        router.push({ name: 'Error' });
      }
    };
    // Update vehicle in transit
    const updateGITInsurance = async () => {
      // ensure only the specified user can edit the data
      /**
       *
       */
      if (user.value.uid === userGuard.value) {
        const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(route.params.id);
        await vehicleRef.update({
          goodsInTransitInsuranceCompany: GITinsuranceCompany.value,
          goodsInTransitInsuranceStart: new Date(GITinsuranceStart.value),
          goodsInTransitInsuranceEnd: getEndDate(GITinsuranceStart.value, 11),
          updatedDate: timestamp(),
          updatedBy: user.value.displayName,
        });
        // Upload image
        imageFiles.value.forEach((data) => {
          const imageName = `${uuidv4()}.jpeg`;
          uploadFile(data.fileData[0], imageName);
          // console.log(images.value);
          const dataFieldName = data.fieldName;
          vehicleRef.update({
            [dataFieldName]: imageName,
          });
        });
        showSuccessMessage.value = 'Goods In Transit Insurance Updated';
        showSuccessAlert.value = true;
        router.go({ name: 'TransporterVehicleEdit', params: { id: route.params.id } });
      } else {
        router.push({ name: 'Error' });
      }
    };
    // Update Road Vehicle Income Tax
    const updateVIT = async () => {
      // ensure only the specified user can edit the data
      /**
       *
       */
      if (user.value.uid === userGuard.value) {
        const vehicleRef = pentermFirestore.collection('transporterVehicles').doc(route.params.id);
        await vehicleRef.update({
          vehiclIncomeTaxStart: new Date(VITStart.value),
          vehiclIncomeTaxEnd: getEndDate(VITStart.value, 2),
          updatedDate: timestamp(),
          updatedBy: user.value.displayName,
        });
        // Upload image
        imageFiles.value.forEach((data) => {
          const imageName = `${uuidv4()}.jpeg`;
          uploadFile(data.fileData[0], imageName);
          // console.log(images.value);
          const dataFieldName = data.fieldName;
          vehicleRef.update({
            [dataFieldName]: imageName,
          });
        });
        showSuccessMessage.value = 'Vehicle Income Tax Updated';
        showSuccessAlert.value = true;
        router.go({ name: 'TransporterVehicleEdit', params: { id: route.params.id } });
      } else {
        router.push({ name: 'Error' });
      }
    };

    // Modal preview
    getVehicle();
    const getImageURL = (imagePath) => {
      const storageRef = firebase.storage().ref();
      storageRef.child(imagePath).getDownloadURL()
        .then((url) => { imageURL.value = url; });
      // console.log(imageURL.value);
    };

    onMounted(() => {
      getDate();
      if (getUserRole.value === 'admin') {
        getVehicle();
      } else if (getUserRole.value === 'driver') {
        router.push({ name: 'TransporterDriverJobs' });
      }
    });

    return {
      user,
      transporterId,
      router,
      minDate,
      maxDate,
      dobMaxYear,
      fileUpload,
      getNumberOfDays,
      updateVehicle,
      dobError, // dob error
      dobErrorStyle, // css
      licenseError,
      licenseErrorStyle, // css
      motError,
      motErrorStyle, // css
      insuranceError,
      insuranceErrorStyle,
      dateResults,
      driverLicenseType,
      driverLicenseNumber,
      driverLicenseExpiry,
      driverRegion,
      dataResults,
      dump,
      error,
      publicPath,
      categoryPicked,
      makeYear,
      vehicleMake,
      vehicleReg,
      vehicleVIN,
      vehicleMakeYear,
      insuranceCompany,
      insuranceType,
      insuranceStart,
      insuranceEnd,
      motStart,
      motEnd,
      GITinsuranceCompany,
      GITinsuranceStart,
      GITinsuranceEnd,
      VITStart,
      VITEnd,
      driverSex,
      driverFullName,
      driverDoB,
      driverPhone,
      driverEmail,
      addressGPS,
      addressPostal,
      vehicleDocuments,
      editDriver,
      showDriverDetails,
      updateVehicleInsurance,
      updateVehicleMot,
      updateGITInsurance,
      updateVIT,
      // Insurance Dates
      insureStart,
      insureEnd,
      roadTaxStart,
      roadTaxEnd,
      goodsIntrasnsitStart,
      goodsIntrasnsitEnd,
      vehicleIncomeTaxStart,
      vehicleIncomeTaxEnd,
      insurancePhoto,
      goodsInTransitPhoto,
      roadWorthyPhoto,
      vehiclIncomeTaxPhoto,
      getImageURL,
      imageURL,
      showSuccessAlert,
      showSuccessMessage,
    };
  },
});
</script>

<style lang="css" scoped>
/* .map {
  width: 100%;
  height: 300px;
  background-color: azure;
} */
.form-stack {
  display: block;
}
.field-margin {
  margin: auto;
  padding: 10px;
}
.page-header-border {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 5px;
}
.page-header {
  margin: 10px auto;
  border-right: 4px solid tomato;
  padding: 10px;
}
.file-input {
  display: none;
}
.hide-imput {
  padding: 1px;
}
.hide-imput input{
  display: none;
}
.date-error {
  border: 1px solid tomato;
}

</style>
