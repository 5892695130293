<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Commission</h6>

            <!-- Title -->
            <h1 class="header-title">Dashboard</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
      <div class="row">
      <div class="col-12 col-lg-4">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <!-- Form -->
            <form @submit.prevent="submitData">
              <div class="row g-3">
                <!-- <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Search Vehicle</label>
                  <input type="text" class="form-control"
                  autocomplete="off" />
                </div> -->
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Vehicle</label>
                  <select class="form-select mb-3" v-model="vehicle"
                  autocomplete="off" required>
                    <option v-for="vehicle in dataResults"
                    :value="vehicle.vehicleReg" :key="vehicle.id">
                      {{vehicle.vehicleReg}}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-6 mb-3">
                  <label class="form-label">Date</label>
                  <input type="text" class="form-control" v-model="commissionDate"
                  autocomplete="off" required ref="expenseDatePicker" />
                </div>
              </div>

              <div class="row g-3">
                <div class="col-12 col-md-12 mb-3">
                  <label class="form-label">Commission</label>
                  <select class="form-select mb-3" v-model="commissionCharge">
                    <option value="0.5">5%</option>
                    <option value="0.1">10%</option>
                    <!-- <option value="0.1">10%</option> -->
                  </select>
                </div>
              </div>
              <div class="row g-3">
                <div class="col-12 col-md-12 mb-3">
                  <label class="form-label">Comments</label>
                  <textarea class="form-control" cols="30" rows="5"
                  required v-model="comments"></textarea>
                </div>
              </div>

              <!-- Button -->
              <button class="btn btn-primary" type="submit">Submit</button>

            </form>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div
            class="table-responsive mb-0"
          data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-project"
                    >
                      Date
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Month
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      year
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Vehicle
                    </a>
                  </th>

                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Commission
                    </a>
                  </th>

                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Comments
                    </a>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="commission in commissionData" :key="commission.id">
                  <td class="goal-project">{{ commission.date.toDate().toDateString() }}</td>
                  <td class="goal-progress">
                    {{ monthNames[commission.month] }}
                  </td>
                  <td class="goal-date">
                    {{ commission.year }}
                  </td>
                  <td class="goal-date">
                    {{ commission.vehicleReg }}
                  </td>
                  <td class="goal-date">
                    {{ commission.commissionCharge * 100 }} %
                  </td>
                  <td class="goal-date">
                    {{ commission.comments }}
                  </td>
                  <td class="text-end">
                    <!-- <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link class="dropdown-item"
                        :to="{ name: 'TransporterJobDetail', params: { id: job.id } }">
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </router-link>
                      </div>
                    </div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script>
import { ref } from '@vue/runtime-dom';
import flatpickr from 'flatpickr';
import firebase from 'firebase';
// import { v4 as uuidv4 } from 'uuid';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { pentermFirestore, timestamp } from '../../../firebase/config';
import getUser from '../../../composables/getUser';

export default {
  name: 'Blank',
  props: [],
  components: {
    // NavCompany,
  },
  setup() {
    const {
      user, transporterId, userDetails,
    } = getUser();
    const router = useRouter();
    // const route = useRoute();
    const expenseDatePicker = ref(null);
    const maintenance = ref('');
    const vehicle = ref('');
    const commissionDate = ref('');
    const dataResults = ref('');
    const commissionData = ref('');
    const paymentTo = ref('');
    const amount = ref('');
    const comments = ref('');
    const dump = ref('');
    const error = ref('');
    const imageFiles = ref([]);
    const commissionCharge = ref('');
    const monthNames = ref(['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ]);

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      console.log(e.target.files);
      imageFiles.value = [...imageFiles.value,
        { fieldName: e.target.id, fileData: e.target.files },
      ];
      // console.log(imageFiles.value);
      // const fileName = imageFiles.value[0][0].name.split('.').slice(-1);
      const fileName = imageFiles.value[0].fileData[0].name.split('.').slice(-1)[0];
      console.log(fileName);
    };

    const uploadFile = (imageFile, imageName, path) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `${path}/${transporterId.value}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(uplodPercent);
      },
      (errors) => { console.log(errors.message); },
      () => { },
      storageRef.snapshot.ref.getDownloadURL().then((url) => {
        // imageURL.value = url;
        // console.log(url);
        const dumped = ref('');
        dumped.value = url;
      }));
    };

    // Submit data
    const submitData = async () => {
      const addData = {
        vehicleReg: vehicle.value,
        date: new Date(commissionDate.value),
        month: new Date(commissionDate.value).getMonth(),
        year: new Date(commissionDate.value).getFullYear(),
        commissionCharge: commissionCharge.value,
        comments: comments.value,
        transporterId: transporterId.value,
        createdDate: timestamp(),
        updatedDate: timestamp(),
        uid: user.value.uid,
        userName: user.value.displayName,
      };
      await pentermFirestore.collection('transporterCommission').add(addData)
        .then((doc) => {
          dump.value = doc;
        });
      commissionDate.value = '';
      comments.value = '';
      router.go({ name: 'Commission' });
    };

    const vehicles = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterVehicles')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .limit(25)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((vehicleData) => ({
              ...vehicleData.data(),
              id: vehicleData.id,
            }));
            // last = snap.docs[snap.docs.length - 1];
            // records.value = snap.docs.length;
            // // console.log(last.data());
            // disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };

    // const updateMaintenance = async (mID, dateData) => {
    //   const maintenanceRef = pentermFirestore
    //     .collection('transporterMaintenance')
    //     .doc(mID);
    //   const res = await maintenanceRef.update({
    //     maintenanceDate: new Date(dateData),
    //   });
    //   dump.value = res;
    // };

    const commissionList = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterCommission')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .limit(25)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            commissionData.value = snap.docs.map((vehicleData) => ({
              ...vehicleData.data(),
              id: vehicleData.id,
            }));
            // snap.forEach((doc) => {
            //   console.log(doc.id);
            //   console.log(doc.data().maintenanceDate);
            //   updateMaintenance(doc.id, doc.data().maintenanceDate);
            // });

            // last = snap.docs[snap.docs.length - 1];
            // records.value = snap.docs.length;
            // // console.log(last.data());
            // disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    commissionList();

    // delete vehicles
    const deleteVehicle = async (id) => {
      await pentermFirestore.collection('transporterCommission').doc(id).delete();
    };
    vehicles();

    onMounted(() => {
      flatpickr(expenseDatePicker.value, {
        minDate: new Date().fp_incr(-14),
        maxDate: 'today',
        allowInput: true,
      });
    });
    return {
      expenseDatePicker,
      fileUpload,
      user,
      uploadFile,
      submitData,
      maintenance,
      vehicle,
      commissionDate,
      paymentTo,
      amount,
      comments,
      dataResults,
      commissionData,
      monthNames,
      commissionCharge,
      deleteVehicle,

    };
  },
};
</script>
<style>

</style>
