<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Files</h6>

            <!-- Title -->
            <h1 class="header-title">Dashboard-</h1>
          </div>
          <div class="col-auto">
            <!-- Button -->
            <!-- <a href="#!" class="btn btn-primary lift"> Create Report </a> -->
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
      <div class="row">
      <div class="col-12 col-lg-4">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <!-- {{listFiles}} -->
            <table class="table table-sm  card-table" v-if="listFiles">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-project"
                    >
                      Date
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Name
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      URL
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="file in listFiles" :key="file.name">
                  <td class="goal-project">{{ file.timeCreated }}</td>
                  <td class="goal-progress">
                    {{ file.name }}
                  </td>
                  <td class="goal-progress">
                    {{ file.url }}
                  </td>
                  <!-- <td>
                     <select class="form-select mb-3" v-model="vehicleAdd"
                  autocomplete="off" required>
                    <option v-for="vehicle in dataResults"
                    :value="vehicle.vehicleReg" :key="vehicle.id">
                      {{vehicle.vehicleReg}}
                    </option>
                  </select>
                  </td>
                  <td>
                    <button type="button" class="btn btn-primary" @click="getExpense(job.id)">
                      Maintenance
                      </button>
                  </td> -->
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div
            class="table-responsive mb-0"
          data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table class="table table-sm  card-table" v-if="expenseData">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-project"
                    >
                      Date
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Vehicle
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      expense Type
                    </a>
                  </th>
                  <th>Comments</th>
                  <th colspan="3"></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="job in expenseData" :key="job.id">
                  <td class="goal-project">{{ job.expenseDate.toDate().toDateString() }}</td>
                  <td class="goal-progress">
                    {{ job.vehicleReg }}
                  </td>
                  <td class="goal-progress">
                    {{ job.expenseType }}
                  </td>
                  <td class="text-start">
                    {{ job.expenseRecieptPhoto }}
                  </td>
                  <!-- <td>
                     <select class="form-select mb-3" v-model="vehicleAdd"
                  autocomplete="off" required>
                    <option v-for="vehicle in dataResults"
                    :value="vehicle.vehicleReg" :key="vehicle.id">
                      {{vehicle.vehicleReg}}
                    </option>
                  </select>
                  </td>
                  <td>
                    <button type="button" class="btn btn-primary" @click="getExpense(job.id)">
                      Maintenance
                      </button>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script>
import { ref } from '@vue/runtime-dom';
import flatpickr from 'flatpickr';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { pentermFirestore, timestamp } from '../../../firebase/config';
import getUser from '../../../composables/getUser';

export default {
  name: 'Blank',
  props: [],
  components: {
    // NavCompany,
  },
  setup() {
    const {
      user, transporterId, userDetails,
    } = getUser();
    const router = useRouter();
    // const route = useRoute();
    const expenseDatePicker = ref(null);
    const expense = ref('');
    const vehicle = ref('');
    const vehicleAdd = ref(''); // Temp
    const expenseDate = ref('');
    const dataResults = ref('');
    const expenseData = ref('');
    const paymentTo = ref('');
    const ammount = ref('');
    const comments = ref('');
    const expenseDesc = ref('');
    const dump = ref('');
    const error = ref('');
    const imageFiles = ref([]);
    const imageURL = ref('');
    const listFiles = ref([]);

    const fileUpload = (e) => {
      /**
       * Get files for upload
       */
      console.log(e.target.files);
      imageFiles.value = [...imageFiles.value,
        { fieldName: e.target.id, fileData: e.target.files },
      ];
      // console.log(imageFiles.value);
      // const fileName = imageFiles.value[0][0].name.split('.').slice(-1);
      const fileName = imageFiles.value[0].fileData[0].name.split('.').slice(-1)[0];
      console.log(fileName);
    };

    const uploadFile = (imageFile, imageName, path) => {
      /**
       * Function to upload to firestore
       */
      const fileName = `${path}/${transporterId.value}/${imageName}`;
      const storageRef = firebase.storage().ref(fileName).put(imageFile);
      // console.log(fileToUpload);
      // console.log(fileToUpload.name);
      storageRef.on('state_changed', (snapshot) => {
        // console.log(snapshot);
        // uploadValue.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const uplodPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(uplodPercent);
      },
      (errors) => { console.log(errors.message); },
      () => { },
      storageRef.snapshot.ref.getDownloadURL().then((url) => {
        // imageURL.value = url;
        // console.log(url);
        const dumped = ref('');
        dumped.value = url;
      }));
    };

    // Submit data
    const submitData = async () => {
      const addData = {
        expenseDesc: expenseDesc.value,
        vehicleReg: vehicle.value,
        expenseType: expense.value,
        expenseDate: new Date(expenseDate.value),
        paymentTo: paymentTo.value,
        ammount: ammount.value,
        comments: comments.value,
        transporterId: transporterId.value,
        createdDate: timestamp(),
        updatedDate: timestamp(),
        uid: user.value.uid,
        userName: user.value.displayName,
      };
      await pentermFirestore.collection('transporterExpenses').add(addData)
        .then((doc) => {
          // console.log(doc.id);
          const expenseRef = pentermFirestore.collection('transporterExpenses').doc(doc.id);
          /**
           * Bulk upload files
           */
          const images = ref([]);
          imageFiles.value.forEach((data) => {
            const imageName = `${uuidv4()}.${data.fileData[0].name.split('.').slice(-1)[0]}`;
            images.value = [...images.value, { docType: data.fieldName, imageFile: imageName }];
            uploadFile(data.fileData[0], imageName, 'uploadedFiles');
            // console.log(images.value);
            const dataFieldName = data.fieldName;
            expenseRef.update({
              [dataFieldName]: imageName,
            });
          });
        });
      expenseDate.value = '';
      paymentTo.value = '';
      ammount.value = '';
      comments.value = '';
      expenseDesc.value = '';
      router.go({ name: 'Expenses' });
    };

    const vehicles = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterVehicles')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .limit(25)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((vehicleData) => ({
              ...vehicleData.data(),
              id: vehicleData.id,
            }));
            // last = snap.docs[snap.docs.length - 1];
            // records.value = snap.docs.length;
            // // console.log(last.data());
            // disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    vehicles();
    // end

    // const updateExpense = async (mID, dateData) => {
    //   const maintenanceRef = pentermFirestore
    //     .collection('transporterExpenses')
    //     .doc(mID);
    //   const res = await maintenanceRef.update({
    //     expenseDate: new Date(dateData),
    //   });
    //   dump.value = res;
    // };

    const expenseList = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterExpenses')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          // .limit(25)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            expenseData.value = snap.docs.map((vehicleData) => ({
              ...vehicleData.data(),
              id: vehicleData.id,
            }));
            // snap.forEach((doc) => {
            //   console.log(doc.id);
            //   console.log(doc.data().expenseDate);
            //   updateExpense(doc.id, doc.data().expenseDate);
            // });

            // last = snap.docs[snap.docs.length - 1];
            // records.value = snap.docs.length;
            // // console.log(last.data());
            // disableBtn.value = false;
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    expenseList();
    // end
    // const expenseListMove = async () => {
    //   try {
    //     const res = await pentermFirestore
    //       .collection('transporterExpenses')
    //       .onSnapshot((snap) => {
    //         console.log(snap.docs);
    //         snap.forEach((doc) => {
    //           console.log(doc.id, '=>', doc.data());
    //           const addDataMove = {
    //             maintenanceType: doc.data().expenseType,
    //             maintenanceDate: doc.data().expenseDate,
    //             paymentTo: doc.data().paymentTo,
    //             maintenanceRecieptPhoto: doc.data().expenseRecieptPhoto,
    //             ammount: doc.data().ammount,
    //             comments: doc.data().comments,
    //             transporterId: doc.data().transporterId,
    //             createdDate: doc.data().createdDate,
    //             updatedDate: doc.data().updatedDate,
    //             uid: doc.data().uid,
    //             userName: doc.data().userName,
    //           };
    //           console.log(addDataMove);
    //         });
    //         // last = snap.docs[snap.docs.length - 1];
    //         // records.value = snap.docs.length;
    //         // // console.log(last.data());
    //         // disableBtn.value = false;
    //       });
    //     // console.log('waiting for data');
    //     dump.value = res;
    //   } catch (err) {
    //     error.value = err.message;
    //   }
    // };
    // expenseListMove();
    // Move expenses

    // Get Customer
    const getExpense = async (expenseID) => {
      try {
        const doc = await pentermFirestore.collection('transporterExpenses').doc(expenseID).get();
        // console.log('waiting for data');
        if (doc) {
          // console.log(doc.data(), '==>', vehicleAdd.value);
          const addDataMove = {
            vehicleReg: vehicleAdd.value,
            maintenanceType: doc.data().expenseType,
            maintenanceDate: doc.data().expenseDate,
            paymentTo: doc.data().paymentTo,
            maintenanceRecieptPhoto: doc.data().expenseRecieptPhoto,
            amount: doc.data().ammount,
            comments: doc.data().comments,
            transporterId: doc.data().transporterId,
            createdDate: doc.data().createdDate,
            updatedDate: doc.data().updatedDate,
            uid: doc.data().uid,
            userName: doc.data().userName,
          };
          console.log(addDataMove);
          await pentermFirestore.collection('transporterMaintenance').add(addDataMove)
            .then(() => {
              console.log('moved');
            });
        }
        // update expense
        const expenseRef = pentermFirestore.collection('transporterExpenses').doc(expenseID);
        await expenseRef.update({ status: 1, transporterId: 'Moved', movedDate: timestamp() });
        // end
      } catch (err) {
        error.value = err.message;
      }
    };

    // // List files
    // console.log(userDetails.value.transporterId);
    // const listFilesAndGetURLs = async (directoryPath = '') => {
    //   const storageRef = firebase.storage().ref(directoryPath);
    //   try {
    //     // List all files in the directory
    //     console.log(storageRef);
    //     const result = await storageRef.listAll();
    //     const fileURLs = await Promise.all(
    //       result.items.map((itemRef) => itemRef.getDownloadURL().then((url) => ({
    //         name: itemRef.name,
    //         url,
    //       }))),
    //     );

    //     // Now you have an array of objects with file names and their URLs
    //     console.log(fileURLs);
    //     return fileURLs;
    //   } catch (errors) {
    //     console.error('Error listing files and getting URLs:', errors);
    //     return [];
    //   }
    // };

    // // Usage
    // listFilesAndGetURLs(`/images/${userDetails.value.transporterId}`).then((files) => {
    //   files.forEach((file) => {
    //     console.log(`File Name: ${file.name}, URL: ${file.url}`);
    //     // console.log(`File Name: ${file.name}, URL: ${file.url}`);
    //   });
    // });
    //  New

    const listFilesSortedByDate = async (directoryPath = '') => {
      const storageRef = firebase.storage().ref(directoryPath);
      try {
        // List all files in the directory
        const result = await storageRef.listAll();
        const filesWithMetadata = await Promise.all(
          result.items.map((itemRef) => itemRef.getMetadata().then((metadata) => ({
            name: itemRef.name,
            url: itemRef.getDownloadURL(), // Fetch the download URL
            timeCreated: new Date(metadata.timeCreated), // Convert timeCreated to a Date object
          }))),
        );

        // Sort the files by date in descending order
        filesWithMetadata.sort((a, b) => b.timeCreated - a.timeCreated);

        // Fetch URLs and return sorted file information
        return await Promise.all(filesWithMetadata.map(async (file) => ({
          ...file,
          url: await file.url,
        })));
      } catch (errors) {
        console.error('Error listing files, getting metadata and URLs:', errors);
        return [];
      }
    };

    // Usage
    listFilesSortedByDate(`/images/${userDetails.value.transporterId}`).then((files) => {
      listFiles.value = files;
      files.forEach((file) => {
        console.log(`File Name: ${file.name}, URL: ${file.url}, Created: ${file.timeCreated}`);
      });
    });

    // delete vehicles
    const deleteVehicle = async (id) => {
      await pentermFirestore.collection('transporterExpenses').doc(id).delete();
    };

    const getImageURL = (imagePath) => {
      const storageRef = firebase.storage().ref();
      storageRef.child(imagePath).getDownloadURL()
        .then((url) => { imageURL.value = url; });
      // console.log(imageURL.value);
    };

    onMounted(() => {
      flatpickr(expenseDatePicker.value, {
        minDate: new Date().fp_incr(-90),
        maxDate: 'today',
        allowInput: true,
      });
    });
    return {
      expenseDatePicker,
      transporterId,
      fileUpload,
      user,
      uploadFile,
      submitData,
      expense,
      vehicle,
      vehicleAdd, // temp
      expenseDate,
      paymentTo,
      ammount,
      comments,
      expenseDesc,
      dataResults,
      expenseData,
      deleteVehicle,
      getExpense,
      getImageURL,
      imageURL,
      listFiles,

    };
  },
};
</script>
<style>

</style>
