import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
// import firebase from 'firebase'
// import * as pentermAdmin from 'firebase-admin';

let databaseURLx = '';
if (window.location.hostname === 'localhost') {
  databaseURLx = 'http://localhost:9000/?ns=penterm-3e586';
} else {
  databaseURLx = 'https://penterm-3e586-default-rtdb.europe-west1.firebasedatabase.app';
}

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCgK75bn-IzWYaUC6lSEzOSRAhMXKVExPQ',
  authDomain: 'penterm-3e586.firebaseapp.com',
  projectId: 'penterm-3e586',
  databaseURL: databaseURLx,
  storageBucket: 'penterm-3e586.appspot.com',
  messagingSenderId: '1062856155599',
  appId: '1:1062856155599:web:c98f5dffca∏43fe22c032ba',
  measurementId: 'G-W0X074GR6L',
};

// init

firebase.initializeApp(firebaseConfig);
// firebase.analytics();
// firebase.functions().useEmulator('localhost', 5001);
// Secondary Project
const firebasePublicConfig = {
  apiKey: 'AIzaSyDqRrDSBe8bsKJjh3clUFC4Q1dBpxKe1lI',
  authDomain: 'pentermpublic.firebaseapp.com',
  databaseURL: 'https://pentermpublic-default-rtdb.firebaseio.com',
  projectId: 'pentermpublic',
  storageBucket: 'pentermpublic.appspot.com',
  messagingSenderId: '671256274001',
  appId: '1:671256274001:web:37b6250301b9accaf74470',
  measurementId: 'G-LWZQ8XFHH7',
};
const secondaryApp = firebase.initializeApp(firebasePublicConfig, 'secondary');

// Auth
const pentermAuth = firebase.auth();
if (window.location.hostname === 'localhost') {
  pentermAuth.useEmulator('http://localhost:9099');
}

// Firestore config
const pentermFirestore = firebase.firestore();
const pentermPublicFirestore = firebase.firestore(secondaryApp);

if (window.location.hostname === 'localhost') {
  pentermFirestore.useEmulator('localhost', 8080);
}

const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {
  pentermFirestore, timestamp, pentermAuth, firebaseConfig, pentermPublicFirestore,
};
