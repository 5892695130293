<template>
  <div class="loader-center" v-if="!dataResults">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
  <!-- HEADER -->
<div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="header-body">
        <div class="row align-items-end">
          <div class="col">
            <!-- Pretitle -->
            <h6 class="header-pretitle">Overview</h6>

            <!-- Title -->
            <h1 class="header-title">Vehicle Owners</h1>
          </div>
          <div class="col-auto">
            <div class="col-auto">
              <!-- Button -->
              <router-link
                class="btn btn-primary lift"
                :to="{ name: 'VehicleOwner' }"
              >
                Add New Owner
              </router-link>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .header-body -->
    </div>
  </div>
  <!-- / .header -->

  <!-- CARDS -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6 col-xl">
        <!-- Value  -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Owners</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> {{ totalOwners }} </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-users text-muted mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Hours -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Vehicles</h6>

                <!-- Heading -->
                <span class="h2 mb-0">
                  {{ totalVehicles }}
                </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-truck text-primary mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Exit -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Maintenace Due</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 2 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-cpu text-warning mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <!-- Time -->
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center gx-0">
              <div class="col">
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">Renewals</h6>

                <!-- Heading -->
                <span class="h2 mb-0"> 1 </span>
              </div>
              <div class="col-auto">
                <!-- Icon -->
                <span class="h2 fe fe-shield text-danger mb-0"></span>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>
    <!-- end of infocards -->
    <div class="row">
      <div class="col-12">
        <!-- Goals -->
        <div class="card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <!-- Form -->
                <form>
                  <div
                    class="
                      input-group
                      input-group-flush
                      input-group-merge
                      input-group-reverse
                    "
                  >
                    <input
                      class="form-control list-search"
                      type="search"
                      placeholder="Search"
                      v-model="searchOwner"
                      @keyup="searchOwners"
                    />
                    <span class="input-group-text">
                      <i class="fe fe-search"></i>
                    </span>
                  </div>
                </form>
              </div>
              <div class="col-auto me-n3">
                <!-- Select -->
                <form>
                  <select
                    class="form-select form-select-sm form-control-flush"
                    data-choices='{"searchEnabled": false}'
                  >
                    <option selected>5 per page</option>
                    <option>10 per page</option>
                    <option>All</option>
                  </select>
                </form>
              </div>
            </div>
            <!-- / .row -->
            <!-- / .row -->
          </div>
          <div
            class="table-responsive mb-0"
          data-list='{"valueNames": ["goal-project", "goal-status", "goal-progress", "goal-date"]}'
          >
            <table class="table table-sm table-nowrap card-table">
              <thead>
                <tr>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-project"
                    >
                      Full Name
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-status"
                    >
                      Status
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Email
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Phone Number
                    </a>
                  </th>
                  <th>
                    <a
                      href="#"
                      class="text-muted list-sort"
                      data-sort="goal-progress"
                    >
                      Added
                    </a>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="owner in dataResults" :key="owner.id">
                  <td class="goal-project">{{ owner.ownerFullName }}</td>
                  <td class="goal-status">
                    <div v-if="owner.status === 0">
                      <span class="text-danger">●</span> InActive
                    </div>
                    <div v-if="owner.status === 1">
                      <span class="text-success">●</span> Active
                    </div>
                  </td>
                  <td class="goal-progress">
                    {{ owner.ownerEmail }}
                  </td>
                  <td class="goal-date">
                    {{ owner.ownerPhone }}
                  </td>
                  <td class="text-start">
                    {{ owner.createdDate.toDate().toDateString() }}
                  </td>
                  <td class="text-end">
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'VehicleOwnerDetail',
                            params: { id: owner.id, pop: 'opp' },
                          }"
                        >
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </router-link>
                  </td>
                  <td class="text-end">
                    <!-- <div class="dropdown">
                      <a
                        href="#"
                        class="dropdown-ellipses dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fe fe-more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'VehicleOwnerDetail',
                            params: { id: owner.id, pop: 'opp' },
                          }"
                        >
                          <span class="fe fe-eye text-muted mb-0"></span> View
                        </router-link>
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'VehicleOwnerDetail',
                            params: { id: owner.id, pop: 'opp' },
                          }"
                        >
                          <span class="fe fe-edit text-muted mb-0"></span> Edit
                        </router-link>
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'VehicleOwnerDetail',
                            params: { id: owner.id, pop: 'opp' },
                          }"
                        >
                          <span class="fe fe-truck text-muted mb-0"></span>
                          Vehicles
                        </router-link>
                      </div>
                    </div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from '@vue/runtime-dom';
import getUser from '../../composables/getUser';
import { pentermFirestore } from '../../firebase/config';

export default {
  name: 'OwnerVehicleList',
  components: {
  },
  setup() {
    const { user, userDetails } = getUser();
    const loggedInUser = ref('');
    const error = ref('');
    const dump = ref('');
    const dataResults = ref([]);
    const formStatus = ref(false);
    const ownerEmail = ref(false);
    const ownerFullName = ref(false);
    const totalOwners = ref('');
    const totalVehicles = ref('');
    const searchOwner = ref('');
    const totalSearch = ref('');

    const showForm = (owner, fullname) => {
      formStatus.value = !formStatus.value;
      ownerEmail.value = owner;
      ownerFullName.value = fullname;
    };

    const getOwners = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterVehicleOwners')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .limit(25)
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((trip) => ({
              ...trip.data(),
              id: trip.id,
            }));
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    // Search owneres
    const getTotalOwners = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterVehicleOwners')
          .where('transporterId', '==', userDetails.value.transporterId)
          // .where('ownerFullName', '==', searchOwner.value)
          .orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            // last = snap.docs[snap.docs.length - 1];
            totalOwners.value = snap.docs.length;
            // // console.log(last.data());
            // disableBtn.value = false;
          });
        dump.value = res;
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };
    // end
    const getVehicles = async () => {
      try {
        const res = await pentermFirestore
          .collection('transporterVehicles')
          .where('transporterId', '==', userDetails.value.transporterId)
          .orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            totalVehicles.value = snap.docs.length;
          });
        dump.value = res;
      } catch (err) {
        // console.log(err.message);
        // console.log('Error occured');
        error.value = err.message;
      }
    };

    onMounted(() => {
      getOwners();
      getVehicles();
      getTotalOwners();
    });

    return {
      user,
      loggedInUser,
      dump,
      error,
      formStatus,
      showForm,
      ownerEmail,
      ownerFullName,
      dataResults,
      totalOwners,
      totalVehicles,
      totalSearch,
      searchOwner,
      // searchOwners,
    };
  },
};
</script>
<style>
.menu {
  border: 1px solid #ccc;
  border-radius: 10px;
  display: block;
}
.owners {
  background: white;
}
.menu-items {
  display: block;
}
.trips {
  width: 98%;
  max-width: 960px;
  margin: 10px auto;
  border-radius: 4px;
  box-shadow: 2px 4px 6px 4px rgba(20, 6, 20, 0.1);
  background: white;
  padding: 10px;
}
.trips:hover {
  background: #f7f9fb;
  cursor: pointer;
}
.ownerdetails {
  display: table-row;
  border: 1px solid #bbb;
}
.dashboard-card {
  border: solid #ccc 1px;
  border-radius: 4px;
  margin: 5px;
}
.side-menu {
  height: 40px;
  vertical-align: middle;
  border: 1px solid #efefef;
  margin: 2px;
}
.side-menu a:hover {
  background-color: #ebedef;
  color: #e55353;
}
</style>
